import { useQuery } from '@apollo/client';

import { useAppFilters, actionTypes } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';
import ErrorAlert from 'components/ErrorAlert';

import { GET_DEFAULT_TASKS } from 'api/queries/companyAssignmentTaskQueries';

const allOption = { value: '', label: 'Alle oppgaver' };

export default function TaskFilter({ disabled = false, size = 'medium' }) {
	const { task, dispatch } = useAppFilters();

	const {
		data: { tasks = [] } = { tasks: [] },
		loading,
		error,
	} = useQuery(GET_DEFAULT_TASKS);

	return (
		<div>
			<ErrorAlert error={error} />

			<Filter
				name="tasks-filter"
				label="Vis oppgave"
				value={task || allOption}
				loading={loading}
				options={[
					allOption,
					...tasks.map(task => ({
						value: task.name,
						label: task.name,
						categoryName: task.category.name,
					})),
				]}
				groupBy={option => option.categoryName}
				onChange={value =>
					dispatch({
						type: actionTypes.SET_TASK,
						payload: value,
					})
				}
				minWidth="250px"
				disabled={disabled}
				size={size}
			/>
		</div>
	);
}
