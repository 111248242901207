import theme from 'web/theme/theme';

import { CompanyContractsDialogContent } from 'web/components/widgets/dialogs/CompanyContractsDialog';

import {
	WidgetList,
	WidgetListLabel,
} from 'web/components/widgets/components/WidgetList';
import WidgetListData from 'web/components/widgets/components/WidgetListData';
import CompanyDocumentsList from '../components/lists/CompanyDocumentsList';

import Widget from 'web/components/widgets/Widget';

import {
	COMPANIES_WITHOUT_CONTRACTS,
	COMPANIES_WITH_INCOMPLETE_CONTRACTS,
	COMPANY_CONTRACTS_MISSING_SIGNATURES,
} from 'api/queries/widgetQueries';

export default function CompanyContractsAndDocumentsWidget() {
	return (
		<Widget title="Dokumenter" titleIcon="file-signature">
			<WidgetList title="Oppdragsavtaler">
				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler oppdragsavtale"
							chipColor={theme.palette.status.error}
						/>
					}
					query={COMPANIES_WITHOUT_CONTRACTS}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Ufulstendige oppdragsavtaler"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_WITH_INCOMPLETE_CONTRACTS}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
					hideIfEmpty={true}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Venter på signering"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANY_CONTRACTS_MISSING_SIGNATURES}
					hideIfEmpty={true}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
					DialogContent={({ items, link }) => (
						<CompanyContractsDialogContent
							items={items}
							link={link}
						/>
					)}
				/>
			</WidgetList>

			<WidgetList title="Dokumentsigneringer">
				<CompanyDocumentsList />
			</WidgetList>
		</Widget>
	);
}
