import { useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

import { useAppFilters } from 'web/contexts/AppFiltersContext';
import { useUser } from 'web/contexts/UserContext';

import Icon from 'components/Icon';

import TaskFilter from 'components/filters/TaskFilter';
import MonthFilter from 'components/filters/MonthFilter';
import YearFilter from 'components/filters/YearFilter';
import DepartmentFilter from 'components/filters/DepartmentFilter';
import AccountantFilter from 'components/filters/AccountantFilter';
import ProjectManagerFilter from 'components/filters/ProjectManagerFilter';
import AccountManagerFilter from 'components/filters/AccountManagerFilter';
import PayrollManagerFilter from 'components/filters/PayrollManagerFilter';
import AnnualAccountManagerFilter from 'components/filters/AnnualAccountManagerFilter';
import ControllerFilter from 'components/filters/ControllerFilter';
import AuditorFilter from 'components/filters/AuditorFilter';
import AccountantLegacyFilter from 'components/filters/AccountantLegacyFilter';
import AccountantRoleFilter from 'components/filters/AccountantRoleFilter';
import AccountantRoleFilterLegacy from 'components/filters/AccountantRoleFilterLegacy';
import CompanyFilter from 'components/filters/CompanyFilter';
import UserRolesFilter from 'components/filters/UserRolesFilter';
import AccountTypeFilter from 'components/filters/AccountTypeFilter';
import AuditingCompanyFilter from 'components/filters/AuditingCompanyFilter';
import SelectedCompanyFilter from 'components/filters/SelectedCompanyFilter';

const Filters = styled.div`
	display: flex;
	flex-direction: ${p => p.direction};
	flex-wrap: wrap;
	align-items: ${p => (p.direction === 'row' ? 'center' : 'flex-start')};
	gap: 20px;

	// Make sure the filters are always full width when in column mode
	${p =>
		p.direction === 'column' &&
		css`
			div[class*='Filter__FilterWrapper'],
			> div {
				min-width: 100%;
				max-width: 100%;
			}
		`}
`;

const ResetButton = styled(Button)`
	display: block;
	margin: 40px auto 0;
`;

export default function AppFilters({ displayFilters = [], direction = 'row' }) {
	const { userHasMinRole } = useUser();
	const { accountantLegacy } = useAppFilters();

	const allFilters = [
		{
			id: 'year',
			display: true,
			disabled: false,
			component: YearFilter,
		},
		{
			id: 'month',
			display: true,
			disabled: false,
			component: MonthFilter,
		},
		{
			id: 'task',
			display: true,
			disabled: false,
			component: TaskFilter,
		},
		{
			id: 'department',
			display: userHasMinRole('admin'),
			disabled: false,
			component: DepartmentFilter,
		},
		{
			id: 'accountant',
			display: true,
			disabled: false,
			component: AccountantFilter,
		},
		{
			id: 'projectManager',
			display: true,
			disabled: false,
			component: ProjectManagerFilter,
		},
		{
			id: 'accountManager',
			display: true,
			disabled: false,
			component: AccountManagerFilter,
		},
		{
			id: 'payrollManager',
			display: true,
			disabled: false,
			component: PayrollManagerFilter,
		},
		{
			id: 'annualAccountsManager',
			display: true,
			disabled: false,
			component: AnnualAccountManagerFilter,
		},
		{
			id: 'controller',
			display: true,
			disabled: false,
			component: ControllerFilter,
		},
		{
			id: 'auditor',
			display: true,
			disabled: false,
			component: AuditorFilter,
		},
		{
			id: 'accountantLegacy',
			display: userHasMinRole('admin'),
			disabled: false,
			component: AccountantLegacyFilter,
		},
		{
			id: 'accountantRole',
			display: true,
			disabled:
				userHasMinRole('admin') &&
				(!accountantLegacy || !accountantLegacy.value),
			component: AccountantRoleFilter,
		},
		{
			id: 'accountantRoleLegacy',
			display: true,
			disabled: false,
			component: AccountantRoleFilterLegacy,
		},
		{
			id: 'userRole',
			display: true,
			disabled: false,
			component: UserRolesFilter,
		},
		{
			id: 'company',
			display: true,
			disabled: false,
			component: CompanyFilter,
		},
		{
			id: 'selectedCompany',
			display: true,
			disabled: false,
			component: SelectedCompanyFilter,
		},
		{
			id: 'accountType',
			display: true,
			disabled: false,
			component: AccountTypeFilter,
		},
		{
			id: 'auditingCompany',
			display: true,
			disabled: false,
			component: AuditingCompanyFilter,
		},
	];

	// We want to keep the order of the filters as they are defined in the allFilters array.
	const activeFilters = displayFilters
		.map(id => allFilters.find(filter => filter.id === id))
		.filter(filter => filter && filter.display);

	if (activeFilters.length === 0) {
		throw new Error('At least one displayFilter is required!');
	}

	return (
		<>
			<Filters direction={direction}>
				{activeFilters.map(filter => {
					const FilterComponent = filter.component;

					return (
						<FilterComponent
							key={filter.id}
							size="small"
							disabled={filter.disabled}
						/>
					);
				})}
			</Filters>

			{activeFilters.length > 1 && (
				<ResetFiltersButton activeFilters={activeFilters} />
			)}
		</>
	);
}

const SuccessText = styled.p`
	text-align: center;
	min-height: 1.5rem;
	margin-top: 0.5rem;
`;
const ResetFiltersButton = ({ activeFilters }) => {
	const { resetFilters } = useAppFilters();

	const [success, setSuccess] = useState(false);

	const handleResetFilters = () => {
		const activeFilterIds = activeFilters.map(filter => filter.id);

		const reset = resetFilters(activeFilterIds);

		if (reset) {
			setSuccess(true);

			setTimeout(() => {
				setSuccess(false);
			}, 2000);
		}
	};

	return (
		<>
			<ResetButton onClick={handleResetFilters} variant="contained">
				<Icon icon="filter-slash" size="sm" /> Nullstill alle filtre
			</ResetButton>

			<SuccessText>{success && <>Filtrene ble nullstilt</>}</SuccessText>
		</>
	);
};
