import { cloneElement } from 'react';
import {
	useLocation,
	BrowserRouter,
	Routes,
	Route,
	Navigate,
} from 'react-router';
import useLocalStorage from 'hooks/useLocalStorageSetting';

import UserHome from 'web/screens/user/UserHome';
import Documents from 'web/screens/user/Documents';
import DocumentEdit from 'web/screens/user/DocumentEdit';
import DocumentShare from 'web/screens/user/DocumentShare';
import SendSigningRequest from 'web/screens/user/SendSigningRequest';
import SelectTenant from 'web/screens/user/SelectTenant';

import UserProfile from 'web/screens/UserProfile';
import NotFound from 'web/screens/NotFound';

import RouteRedirect from 'web/routes/components/RouteRedirect';

export default function UserRoutes() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={<ComponentWithCompanyId element={<UserHome />} />}
				/>

				<Route path="dokumenter">
					<Route
						index
						element={
							<ComponentWithCompanyId element={<Documents />} />
						}
					/>

					<Route path=":documentNodeId">
						<Route
							index
							element={
								<ComponentWithCompanyId
									element={<Documents />}
								/>
							}
						/>

						<Route
							path="rediger"
							element={
								<ComponentWithCompanyId
									element={<DocumentEdit />}
								/>
							}
						/>

						<Route
							path="del"
							element={
								<ComponentWithCompanyId
									element={<DocumentShare />}
								/>
							}
						/>

						<Route
							path="signer"
							element={
								<ComponentWithCompanyId
									element={<SendSigningRequest />}
								/>
							}
						/>
					</Route>
				</Route>

				<Route path="mine-bedrifter" element={<SelectTenant />} />

				<Route path="profil" element={<UserProfile />} />

				<Route path="404" element={<NotFound />} />

				<Route path="*" element={<RouteRedirect redirect="/404" />} />
			</Routes>
		</BrowserRouter>
	);
}

function ComponentWithCompanyId({ element }) {
	const location = useLocation();

	const { value: tenant } = useLocalStorage('tenant');
	const companyId = tenant?._id;

	if (!companyId) {
		return (
			<Navigate
				to="/mine-bedrifter"
				state={{ from: location.pathname }}
				replace
			/>
		);
	}

	return cloneElement(element, { companyId });
}
