import { useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditNoteIcon from '@mui/icons-material/EditNote';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useUser } from 'web/contexts/UserContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import EditSigneeDialog from 'web/screens/dialogs/documents/EditSigneeDialog';
import SignatureReminderDialog from 'web/screens/dialogs/documents/SignatureReminderDialog';

import ErrorMessage from 'components/ErrorMessage';
import DocumentIcon from 'components/DocumentIcon';

import { REMOVE_SIGNATURE_REQUEST_SIGNEE } from 'api/mutations/signatureMutations';
import { GET_ACTIVE_SIGNATURE_REQUEST } from 'api/queries/signatureQueries';

const SignersWrapper = styled.div`
	margin-top: 15px;
`;

const Title = styled.h4`
	margin: 0 0 15px 0;
`;

const List = styled.div`
	width: 100%;
`;

const Item = styled.div`
	background: ${p => p.theme.palette.background.gray};
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 5px;
	padding: 15px;
`;

const Data = styled.div`
	flex: 1 1 0%;
`;

const Email = styled.div`
	font-weight: 400;
	font-size: 18px;
	text-align: left;
`;

const Text = styled.span`
	font-weight: 400;
	font-size: 13px;
	text-align: left;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 10px;
`;

export default function Signers({
	documentId,
	title,
	showSignedSignees = true,
}) {
	const {
		data: { getActiveSignatureRequest = {} } = {},
		loading,
		error: getActiveSignatureRequestError,
	} = useQuery(GET_ACTIVE_SIGNATURE_REQUEST, {
		variables: {
			documentId,
		},
		errorPolicy: 'all',
	});

	if (loading) {
		return <div>Laster…</div>;
	}

	const {
		signees,
		_id: signatureRequestId,
		signUrl,
	} = getActiveSignatureRequest || {};

	return (
		<div>
			<SignersWrapper>
				{title && <Title>{title}</Title>}

				<ErrorMessage errors={[getActiveSignatureRequestError]} />

				{showSignedSignees && (
					<List>
						{signees?.map((signee, index) => (
							<Signer
								key={index}
								signee={signee}
								signees={signees}
								signUrl={signUrl}
								documentId={documentId}
								signatureRequestId={signatureRequestId}
							/>
						))}
					</List>
				)}
			</SignersWrapper>
		</div>
	);
}

function Signer({ signees, signee, signUrl, documentId, signatureRequestId }) {
	if (signee.signed) return null;

	const [
		removeSignatureSignee,
		{
			loading: removeSignatureSigneeLoading,
			error: removeSignatureSigneeError,
		},
	] = useMutation(REMOVE_SIGNATURE_REQUEST_SIGNEE, {
		refetchQueries: ['getActiveSignatureRequest'],
	});

	const { user } = useUser();

	const signeeLabel = [];
	if (signee.userId && !signee.userName) {
		signeeLabel.push('Bruker finnes ikke lenger');
	} else if (signee.userId) {
		signeeLabel.push(signee.userName);
	}
	if (signee.email) signeeLabel.push(signee.email);
	if (signee.phone) signeeLabel.push(signee.phone);

	return (
		<div>
			<Item>
				<div>
					<DocumentIcon
						needsSignature={true}
						hasSignature={signee.signed}
					/>
				</div>

				<Data>
					<div>
						<Email>{signeeLabel.join(' - ')}</Email>

						<Text>
							{signee.signed ? 'Signert' : 'Mangler signering'}
						</Text>
					</div>
				</Data>

				{!signee.signed && (
					<ButtonsWrapper>
						{signee.userId === user._id && (
							<Sign signUrl={signUrl} />
						)}

						<RemindSignee documentId={documentId} signee={signee} />

						<EditSignee
							signatureRequestId={signatureRequestId}
							signees={signees}
							signee={signee}
						/>

						<DeleteSignee
							signatureRequestId={signatureRequestId}
							signees={signees}
							signee={signee}
							disabled={
								removeSignatureSigneeLoading ||
								signees.length === 1
							}
							removeSignatureSignee={removeSignatureSignee}
						/>
					</ButtonsWrapper>
				)}
			</Item>

			{removeSignatureSigneeError && (
				<ErrorMessage
					title="Kunne ikke slettes"
					errors={['Noe gikk galt under sletting av mottaker']}
				/>
			)}
		</div>
	);
}

function Sign({ signUrl }) {
	if (!signUrl) return;

	return (
		<Tooltip title="Signer dokument">
			<span>
				<IconButton
					aria-label="Signer dokument"
					href={signUrl}
					target="_blank"
				>
					<EditNoteIcon />
				</IconButton>
			</span>
		</Tooltip>
	);
}

function RemindSignee({ documentId, signee }) {
	const [disabled, setDisabled] = useState(false);
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Tooltip title="Send påminnelse">
				<span>
					<IconButton
						aria-label="Send påminnelse"
						disabled={disabled}
						onClick={() => {
							setShowModal(true);
						}}
					>
						<NotificationsIcon />
					</IconButton>
				</span>
			</Tooltip>

			<SignatureReminderDialog
				signee={signee}
				documentId={documentId}
				open={showModal}
				onClose={() => {
					setShowModal(false);
				}}
				onSuccess={() => {
					setDisabled(true);
				}}
			/>
		</>
	);
}

function EditSignee({ signatureRequestId, signees, signee }) {
	const [isOpenDialog, setIsOpenDialog] = useState(false);

	return (
		<>
			<Tooltip title="Rediger mottaker">
				<span>
					<IconButton
						aria-label="Rediger mottaker"
						onClick={() => setIsOpenDialog(true)}
					>
						<EditIcon />
					</IconButton>
				</span>
			</Tooltip>

			{isOpenDialog && (
				<EditSigneeDialog
					isOpen={isOpenDialog}
					setIsOpen={setIsOpenDialog}
					signee={signee}
					signees={signees}
					signatureRequestId={signatureRequestId}
				/>
			)}
		</>
	);
}

function DeleteSignee({
	signatureRequestId,
	signees,
	signee,
	disabled,
	removeSignatureSignee,
}) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const handleDeleteSignee = async () => {
		const signedCount = signees.filter(signee => signee.signed).length;

		const notSignedCount = signees.filter(signee => !signee.signed).length;

		let confirmMessage =
			'Er du sikker på at du ønsker å fjerne denne mottakeren?';

		if (signedCount > 0 && notSignedCount === 1) {
			confirmMessage =
				'Hvis du fjerner den siste mottakeren som ikke har signert, vil signeringsprosessen bli ferdigstilt. Er du sikker på at du vil fjerne denne mottakeren?';
		}

		if (
			!(await verify({
				title: 'Fjern mottaker',
				text: confirmMessage,
			}))
		) {
			return;
		}

		try {
			const {
				data: { removed },
			} = await removeSignatureSignee({
				variables: {
					_id: signatureRequestId,
					signee: {
						email: signee?.email,
						phone: signee?.phone,
						userId: signee?.userId,
					},
				},
			});

			if (removed) {
				notify('Mottaker ble fjernet!');
			}
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Tooltip title="Slett mottaker">
			<span>
				<IconButton
					aria-label="Slett mottaker"
					disabled={disabled}
					onClick={() => handleDeleteSignee()}
				>
					<DeleteIcon />
				</IconButton>
			</span>
		</Tooltip>
	);
}
