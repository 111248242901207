import { useState } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import useKeyboardShortcut from 'hooks/useKeyboardShortcut';

import Icon from 'components/Icon';

import AppFiltersActive from 'components/filters/AppFiltersActive';

import {
	Content as LayoutContent,
	ArchiveHeader,
	ArchiveHeaderRow,
} from 'web/components/Layout';

import AppFiltersSidebar from 'web/components/AppFiltersSidebar';

const wrapperMinWidth = '1500px';
const wrapperMaxWidth = '1499.9px';

const Wrapper = styled.div`
	container-name: wrapper;
	container-type: inline-size;
`;

const Layout = styled(LayoutContent)`
	@container wrapper (min-width: ${wrapperMinWidth}) {
		display: grid;
		grid-template-columns: 1fr 300px;
		grid-auto-rows: auto; /* Allow rows to be auto height */

		gap: 30px;
	}
`;

const Backdrop = styled.div`
	display: none;
	@container wrapper (max-width: ${wrapperMaxWidth}) {
		.drawer-open & {
			display: block;
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 999;
		}
	}
`;

const Content = styled.div`
	width: 100%;
	overflow-x: auto;
	@container wrapper (min-width: ${wrapperMinWidth}) {
		border-right: 1px solid ${p => p.theme.palette.divider};
		padding-right: 30px;
	}
`;

const Drawer = styled.div`
	width: 100%;
	@container wrapper (max-width: ${wrapperMaxWidth}) {
		position: fixed;
		right: 0;
		top: 0;
		z-index: 1051;
		height: 100vh;
		overflow-y: auto;
		width: 300px;
		background-color: ${p => p.theme.palette.background.gray};
		border-left: 1px solid ${p => p.theme.palette.divider};
		transition: transform 0.3s ease;
		transform: translateX(100%);
		padding: 30px;

		&.drawer-open {
			transform: translateX(0);
		}
	}
`;

const OpenFilterButton = styled(Button)`
	display: none;
	@container wrapper (max-width: ${wrapperMaxWidth}) {
		display: flex;
		align-self: flex-start;
		min-width: 104px;
	}
`;

const CloseFilterButton = styled(IconButton)`
	display: none;
	@container wrapper (max-width: ${wrapperMaxWidth}) {
		display: flex;
		position: absolute;
		right: 0px;
		top: 0px;
		font-size: 1.2rem;
	}
`;

/**
 * ContentWithSidebarFilters Component
 *
 * This component renders a layout with a main content area and a sidebar with AppFilters.
 *
 * @component
 * @param {React.ReactNode} children - The main content to be displayed.
 * @param {React.ReactNode} drawer - The content to be displayed inside the drawer.
 * @param {React.ReactNode} headerComponents - Components to be displayed in the header.
 * @param {Object} [props] - Additional props to be passed to the Layout component.
 * @returns {JSX.Element} The rendered component.
 */
export default function ContentWithSidebarFilters({
	children,
	headerComponents,
	displayFilters = [],
	...props
}) {
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const className = open ? 'drawer-open' : '';

	return (
		<Wrapper>
			<Layout className={className} {...props}>
				<Content>
					<ArchiveHeader>
						{headerComponents && (
							<ArchiveHeaderRow>
								{headerComponents}
							</ArchiveHeaderRow>
						)}

						<AppFiltersActive
							displayFilters={displayFilters}
							openFilterButton={
								<OpenFilterButton
									onClick={() => setOpen(true)}
									variant="outlined"
									size="small"
								>
									Åpne filtrering
								</OpenFilterButton>
							}
						/>
					</ArchiveHeader>

					{children}
				</Content>

				<BackdropComponent handleClose={handleClose} />

				<Drawer className={className}>
					<CloseFilterButton onClick={handleClose}>
						<Icon icon="times" />
					</CloseFilterButton>

					<div>
						<AppFiltersSidebar displayFilters={displayFilters} />
					</div>
				</Drawer>
			</Layout>
		</Wrapper>
	);
}

// Isolated backdrop component
const BackdropComponent = ({ handleClose }) => {
	useKeyboardShortcut('Escape', handleClose);

	return <Backdrop onClick={handleClose} />;
};
