import { gql } from '@apollo/client';

export const CREATE_DEFAULT_COMPANY_ASSIGNMENT_TASKS = gql`
	mutation createDefaultCompanyAssignmentTasks(
		$companyId: ID!
		$startDate: DateTime!
		$frequency: String!
		$deadline: TaskDeadlineInput!
		$includeSalary: Boolean
		$salaryFrequency: String
		$salaryDeadline: TaskDeadlineInput
	) {
		tasksCreated: createDefaultCompanyAssignmentTasks(
			companyId: $companyId
			startDate: $startDate
			frequency: $frequency
			deadline: $deadline
			includeSalary: $includeSalary
			salaryFrequency: $salaryFrequency
			salaryDeadline: $salaryDeadline
		)
	}
`;

export const DELETE_ONE_COMPANY_ASSIGNMENT_TASK = gql`
	mutation deleteOneCompanyAssignmentTask($_id: ID!) {
		deleted: deleteOneCompanyAssignmentTask(_id: $_id)
	}
`;

export const DEACTIVATE_TASKS_ACTIVE_FOR_CATEGORY = gql`
	mutation deactivateTasksActiveForCategory(
		$companyId: ID!
		$categoryName: String!
	) {
		ok: deactivateTasksActiveForCategory(
			companyId: $companyId
			categoryName: $categoryName
		)
	}
`;

export const INSERT_ONE_COMPANY_ASSIGNMENT_TASK = gql`
	mutation insertOneCompanyAssignmentTask(
		$companyId: ID!
		$input: NewCompanyAssignmentTaskInput!
	) {
		task: insertOneCompanyAssignmentTask(
			companyId: $companyId
			input: $input
		) {
			_id
		}
	}
`;

export const UPDATE_ONE_COMPANY_ASSIGNMENT_TASK = gql`
	mutation updateOneCompanyAssignmentTask(
		$_id: ID!
		$input: EditCompanyAssignmentTaskInput!
	) {
		task: updateOneCompanyAssignmentTask(_id: $_id, input: $input) {
			_id
		}
	}
`;

export const SET_COMPANY_ASSIGNMENT_TASK_ACTIVE_STATUS = gql`
	mutation setCompanyAssignmentTaskActiveStatus(
		$_id: ID!
		$active: Boolean!
	) {
		status: setCompanyAssignmentTaskActiveStatus(_id: $_id, active: $active)
	}
`;

export const UPDATE_ONE_TASK_DEADLINE_ENTRY = gql`
	mutation updateOneTaskDeadlineEntry(
		$_id: ID!
		$input: TaskDeadlineEntryInput!
	) {
		deadlineEntry: updateOneTaskDeadlineEntry(_id: $_id, input: $input) {
			_id
		}
	}
`;

export const DELETE_ONE_TASK_DEADLINE_ENTRY = gql`
	mutation deleteOneTaskDeadlineEntry($_id: ID!) {
		deleted: deleteOneTaskDeadlineEntry(_id: $_id)
	}
`;

export const TOGGLE_TASK_DEADLINE_ENTRY_SUB_TASK = gql`
	mutation toggleTaskDeadlineEntrySubTask($_id: ID!, $index: Int!) {
		toggled: toggleTaskDeadlineEntrySubTask(_id: $_id, index: $index)
	}
`;

export const INSERT_ONE_TASK_DEADLINE_ENTRY = gql`
	mutation insertOneTaskDeadlineEntry($taskId: ID!, $date: DateTime!) {
		deadlineEntry: insertOneTaskDeadlineEntry(
			taskId: $taskId
			date: $date
		) {
			_id
		}
	}
`;

export const SET_TASK_DEADLINE_ENTRIES_POSTPONED_STATUS_EXPORTED = gql`
	mutation setTaskDeadlineEntriesPostponedStatusExported(
		$deadlineEntryIds: [ID!]!
	) {
		updated: setTaskDeadlineEntriesPostponedStatusExported(
			deadlineEntryIds: $deadlineEntryIds
		)
	}
`;
