/**
 * Formats an array of elements into a language-specific string using conjunctions.
 *
 * If the Intl.ListFormat API is available, it will use it to format the array
 * into a more human-readable string with conjunctions (e.g., "A, B, og C").
 * If not, it will fall back to a simple comma-separated string.
 *
 * @param {Array} array - The array of elements to format.
 * @param {Array<string>|null} [fields=null] - Optional. An array of keys for the
 *                                             properties to concatenate when the array elements are objects.
 *                                             If null, the array elements themselves are used.
 * @returns {string} The formatted string.
 *
 * @example
 * conjunctionFormat(['apple', 'banana', 'cherry']);
 * // Returns "apple, banana, og cherry" if Intl.ListFormat is supported.
 *
 * @example
 * conjunctionFormat([{name: 'apple', type: 'fruit'}, {name: 'banana', type: 'fruit'}], ['name', 'type']);
 * // Returns "apple fruit, banana fruit" if Intl.ListFormat is supported.
 */
export function conjunctionFormat(array, fields = null) {
	if ('ListFormat' in Intl) {
		const formatter = new Intl.ListFormat('nb', {
			style: 'long',
			type: 'conjunction',
		});

		const list = fields
			? array.map(item =>
					fields
						.map(field => item[field])
						.filter(Boolean)
						.join(' ')
				)
			: array;

		return formatter.format(list);
	}

	return fields
		? array
				.map(item =>
					fields
						.map(field => item[field])
						.filter(Boolean)
						.join(' ')
				)
				.join(', ')
		: array.join(', ');
}
