import ErrorMessage from 'components/ErrorMessage';

import ProffDataDeviationsPanel from 'web/components/panels/ProffDataDeviationsPanel';
import ProffDataPanel from 'web/components/panels/ProffDataPanel';
import UpdateProffDataButton from './UpdateProffDataButton';

export default function ProffTab({
	companyId,
	company,
	companyLoading,
	setCreateUserModal,
}) {
	return (
		<>
			{!companyLoading && !company?.orgNo && (
				<ErrorMessage
					title="Bedriften mangler organisasjonsnummer!"
					errors={
						'Du må legge inn dette før du kan hente data fra proff.no.'
					}
				/>
			)}

			{company && company.orgNo && (
				<ProffDataDeviationsPanel
					companyId={company._id}
					deviations={company.dataDeviations.proffDeviations}
					deviationExceptions={company.proffDataDeviationExceptions}
					setCreateUserModal={setCreateUserModal}
				/>
			)}

			{company?.proffData && <ProffDataPanel data={company.proffData} />}

			{company?.orgNo && (
				<UpdateProffDataButton
					companyId={companyId}
					hasProffData={!!company.proffData}
				/>
			)}
		</>
	);
}
