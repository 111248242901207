import { useEffect, useMemo } from 'react';

import {
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { getCompaniesArchiveTemplates } from 'utils/visibilityTemplates';

export default function GridToolbar({
	setSelectedTemplate,
	selectedTemplate,
	columns = [],
}) {
	const companiesArchiveTemplates = useMemo(
		() => getCompaniesArchiveTemplates(columns),
		[columns]
	);

	useEffect(() => {
		if (companiesArchiveTemplates.length > 0 && !selectedTemplate) {
			setSelectedTemplate(companiesArchiveTemplates[0]);
		}
	}, [companiesArchiveTemplates]);

	return (
		<>
			<GridToolbarContainer
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					marginBottom: 2,
				}}
			>
				<Autocomplete
					options={[...companiesArchiveTemplates]}
					renderInput={params => (
						<TextField
							{...params}
							label="Visningsmodus"
							name="company-table-template"
						/>
					)}
					value={selectedTemplate || companiesArchiveTemplates[0]}
					onChange={(_, newValue) => {
						setSelectedTemplate(newValue);
					}}
					size="small"
					sx={{ width: 200 }}
				/>

				<GridToolbarColumnsButton />
				<GridToolbarDensitySelector />
			</GridToolbarContainer>
		</>
	);
}
