import { gql } from '@apollo/client';

export const UPDATE_SIGNATURE_REQUEST_SIGNEES = gql`
	mutation updateSignatureRequest($_id: ID!, $signees: [SigneeInput]!) {
		updateSignatureRequest(_id: $_id, signees: $signees)
	}
`;

export const REMOVE_SIGNATURE_REQUEST_SIGNEE = gql`
	mutation removeSignatureRequestSignee($_id: ID!, $signee: SigneeInput!) {
		removed: removeSignatureRequestSignee(_id: $_id, signee: $signee)
	}
`;

export const ADD_SIGNEES_TO_DOCUMENT = gql`
	mutation addContactsToSignDocument(
		$documentId: ID!
		$signees: [SigneeInput]
	) {
		signatureRequest: addContactsToSignDocument(
			documentId: $documentId
			signees: $signees
		) {
			status
			type
			signees {
				email
				phone
			}
			documentId
			textToSign
			fileHash
		}
	}
`;

export const RESEND_SIGNING_REQUEST = gql`
	mutation resendSigningRequest(
		$documentId: ID
		$signee: SigneeInput
		$notifyEmail: Boolean
		$notifySMS: Boolean
	) {
		signatureRequest: resendSigningRequest(
			documentId: $documentId
			signee: $signee
			notifyEmail: $notifyEmail
			notifySMS: $notifySMS
		) {
			status
			type
			signees {
				email
				phone
			}
			documentId
			textToSign
			fileHash
		}
	}
`;

export const CANCEL_ALL_SIGNATURE_REQUESTS = gql`
	mutation cancelAllSignatureRequests($documentId: ID!) {
		canceled: cancelAllSignatureRequests(documentId: $documentId)
	}
`;
