import { useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';

import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';

import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';

import Form, { Checkbox, CheckboxGroup } from 'web/form/Form';
import ErrorMessage from 'components/ErrorMessage';

import { SEND_COMPANY_CONTRACT_TO_SIGNING } from 'api/mutations/companyContractMutations';
import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';

export default function SendContractSigningRequestDialog({
	contractId,
	open,
	onClose,
}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="xs"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Send til signering
			</DialogTitle>

			<DialogCloseButton onClick={onClose} />

			<SendContractSigningRequestForm
				contractId={contractId}
				onClose={onClose}
			/>
		</Dialog>
	);
}

function SendContractSigningRequestForm({ contractId, onClose }) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [
		sendToSigning,
		{ loading: sendToSigningLoading, error: sendToSigningError },
	] = useMutation(SEND_COMPANY_CONTRACT_TO_SIGNING, {
		refetchQueries: ['findOneCompanyContract'],
	});

	const onSubmit = async ({ notifyEmail, notifySMS }, { setError }) => {
		if (!notifyEmail && !notifySMS) {
			setError('checkboxes', {
				type: 'manual',
				message: 'Minst ett av alternativene må være valgt',
			});
			return;
		}

		try {
			if (
				!(await verify({
					title: 'Send til signering',
					text: `Er du sikker på at du ønsker å sende kontrakten til signering? Det vil ikke være mulig å gjøre endringer etter dette.`,
				}))
			) {
				return;
			}

			const { data } = await sendToSigning({
				variables: {
					_id: contractId,
					notifyEmail,
					notifySMS,
				},
			});

			if (data?.ok) {
				notify('Oppdragsavtalen ble sendt til signering!');
				onClose();
			}
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Form
			values={{
				notifyEmail: true,
				notifySMS: false,
			}}
		>
			<DialogContent
				sx={{
					pt: 0,
				}}
			>
				<ErrorMessage errors={[sendToSigningError]} />

				<NotificationChannelCheckboxes />
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Avbryt</Button>

				<SubmitButton
					onSubmit={onSubmit}
					loading={sendToSigningLoading}
				/>
			</DialogActions>
		</Form>
	);
}
function NotificationChannelCheckboxes() {
	const { clearErrors } = useFormContext();

	return (
		<CheckboxGroup name="checkboxes" label="Velg varslingsmetode:">
			<Stack direction="row" spacing={2}>
				<Checkbox
					name="notifyEmail"
					label="E-post"
					onFocus={() => clearErrors()}
				/>

				<Checkbox
					name="notifySMS"
					label="SMS"
					onFocus={() => clearErrors()}
				/>
			</Stack>
		</CheckboxGroup>
	);
}

const SubmitButton = ({ onSubmit, loading }) => {
	const { handleSubmit, setError } = useFormContext();

	return (
		<Button
			variant="contained"
			onClick={handleSubmit(async values =>
				onSubmit(values, { setError })
			)}
			startIcon={<SendIcon />}
			disabled={loading}
		>
			Send til signering
		</Button>
	);
};
