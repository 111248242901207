export const roleScopes = {
	admin: ['*'],
	accountant: [
		'assignmentRisks:*',
		'statistics:read',
		'documentNodes:*',
		'signatures:*',
		'tenants:*',
		'tenantCategories:read',
		'companyClassificationCodes:read',
		'companyContracts:*',
		'companyRoutines:*',
		'companyRisks:*',
		'companyRiskActions:*',
		'companyControls:*',
		'companyAssignmentTasks:*',
		'todos:*',
		'roles:read',
		'files:*',
		'comments:*',
		'users:*',
		'auditingcompanies:*',
		'helpPages:read',
		'governmentDeadlines:read',
	],
	auditor: [
		'assignmentRisks:*',
		'documentNodes:*',
		'signatures:*',
		'tenants:read',
		'tenantCategories:read',
		'companyContracts:read',
		'companyRoutines:read',
		'companyRiskActions:read',
		'roles:read',
		'files:*',
		'comments:*',
		'users:*',
		'auditingcompanies:read',
	],
	user: [
		'documentNodes:*',
		'signatures:*',
		'tenants:read',
		'companyContracts:read',
		'companyRoutines:read',
		'roles:read',
		'files:*',
		'users:*',
	],
	silent: null,
};

export const roles = [
	{
		_id: 'silent',
		name: 'Inaktiv',
		scopes: roleScopes.silent,
	},
	{
		_id: 'user',
		name: 'Bruker',
		scopes: roleScopes.accountant,
	},
	{
		_id: 'auditor',
		name: 'Revisor',
		scopes: roleScopes.auditor,
	},
	{
		_id: 'accountant',
		name: 'Regnskapsfører',
		scopes: roleScopes.accountant,
	},
	{
		_id: 'admin',
		name: 'Administrator',
		scopes: roleScopes.admin,
	},
];

export default roles;

export function getRoleName(role) {
	return roles.find(r => r._id === role)?.name || 'Ikke tildelt';
}
