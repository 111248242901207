import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import { useUser } from 'web/contexts/UserContext';
import { useAppFilters } from 'web/contexts/AppFiltersContext';

import ErrorMessage from 'components/ErrorMessage';

import SearchFilter from 'components/filters/SearchFilter';

import DesktopLayout from 'web/components/Layout';
import ContentWithSidebarFilters from 'web/components/ContentWithSidebarFilters';
import CreateFabButton from 'web/components/CreateFabButton';
import CompaniesTable from 'web/components/companiesArchive/CompaniesTable';

import { PAGINATE_TENANTS } from 'api/queries/tenantQueries';
import { GET_COMPANY_TABLE_TASKS } from 'api/queries/companyAssignmentTaskQueries';
import Loading from 'components/Loading';

import useLocalStorage from 'hooks/useLocalStorageSetting';

const displayFilters = [
	'month',
	'year',
	'department',
	'accountant',
	'projectManager',
	'accountManager',
	'payrollManager',
	'annualAccountsManager',
	'controller',
	'auditor',
	'accountType',
	'company',
];

export default function CompaniesArchive() {
	const { userHasMinRole } = useUser();

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Bedrifter',
				},
			]}
			title="Bedrifter"
		>
			<ContentWithSidebarFilters
				displayFilters={displayFilters}
				headerComponents={
					<SearchFilter label="Søk etter bedrift" size="small" />
				}
			>
				<Content />
			</ContentWithSidebarFilters>

			{userHasMinRole('admin') && <CreateFabButton to="ny/" />}
		</DesktopLayout>
	);
}

function Content() {
	const { search, activeFilters } = useAppFilters();

	const { value: pageSize, setValue: setPageSize } = useLocalStorage(
		'companiesArchivePageSize',
		15
	);

	// pagination and order states needs to be controlled by the parent component which fetches the data
	const [paginationModel, setPaginationModel] = useState({
		pageSize: pageSize || 15,
		page: 0,
	});

	const [order, setOrder] = useState(1);

	const {
		department,
		accountant,
		projectManager,
		accountManager,
		payrollManager,
		annualAccountsManager,
		controller,
		auditor,
		company,
		accountType,
	} = activeFilters;

	// Get all companies to display in the table
	const {
		data: { companies = { items: [] } } = {
			companies: { items: [] },
		},
		loading,
		error,
	} = useQuery(PAGINATE_TENANTS, {
		variables: {
			search,
			filter: {
				departmentId: department,
				accountantId: accountant,
				projectManagerId: projectManager,
				accountManagerId: accountManager,
				payrollManagerId: payrollManager,
				annualAccountsManagerId: annualAccountsManager,
				controllerId: controller,
				auditorId: auditor,
				company: company,
				accountType: accountType,
			},
			orderBy: 'name',
			order,
			offset: paginationModel.page * paginationModel.pageSize,
			limit: paginationModel.pageSize,
		},
	});

	// Get all tasks to use as columns in the table
	const {
		data: { tasks = [] } = { tasks: [] },
		loading: tasksLoading,
		error: tasksError,
	} = useQuery(GET_COMPANY_TABLE_TASKS);

	const taskNames = useMemo(() => tasks.map(task => task.name), [tasks]);

	if (tasksLoading) return <Loading />;

	return (
		<>
			<ErrorMessage errors={[error, tasksError]} />

			<CompaniesTable
				loading={loading}
				companies={companies?.items || []}
				tasks={taskNames}
				totalCompaniesCount={companies?.count}
				paginationModel={paginationModel}
				setPaginationModel={newModel => {
					setPageSize(newModel.pageSize);
					setPaginationModel(newModel);
				}}
				setNewOrder={setOrder}
			/>
		</>
	);
}
