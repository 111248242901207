import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SyncIcon from '@mui/icons-material/Sync';

import ErrorAlert from 'components/ErrorAlert';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import { UPDATE_COMPANY_PROFFDATA } from 'api/mutations/tenantMutations';

const Wrapper = styled.div`
	margin-top: 24px;
`;

export default function UpdateProffDataButton({ companyId, hasProffData }) {
	const { notify } = useSnackbar();

	const [updateProffData, { error: proffDataError }] = useMutation(
		UPDATE_COMPANY_PROFFDATA,
		{
			onCompleted: () => {
				notify('Data fra proff.no ble oppdatert');
			},
			refetchQueries: ['findOneTenant'],
		}
	);

	async function handleUpdateProffData() {
		try {
			await updateProffData({
				variables: {
					_id: companyId,
				},
			});
		} catch (err) {
			console.warn(err);
		}
	}

	if (!companyId) {
		console.warn('UpdateProffDataButton is missing companyId prop');
		return null;
	}

	return (
		<Wrapper>
			<ErrorAlert error={proffDataError} />

			<Button
				startIcon={hasProffData ? <SyncIcon /> : <CloudDownloadIcon />}
				variant="contained"
				color="primary"
				onClick={async e => {
					e.preventDefault();
					e.stopPropagation();
					handleUpdateProffData();
				}}
			>
				{hasProffData
					? 'Oppdater data fra proff.no'
					: 'Hent data fra proff.no'}
			</Button>
		</Wrapper>
	);
}
