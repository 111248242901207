import { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import Collapse from '@mui/material/Collapse';
import Skeleton from '@mui/material/Skeleton';

import {
	useAppFilters,
	renameFilterKeys,
} from 'web/contexts/AppFiltersContext';

import DesktopLayout from 'web/components/Layout';
import ContentWithSidebarFilters from 'web/components/ContentWithSidebarFilters';
import { UnderlineLink } from 'web/components/Link';

import { InlineLoading } from 'components/Loading';
import ErrorAlert from 'components/ErrorAlert';

import {
	GET_TOTAL_COMPANY_CLASSIFICATION_COUNTS,
	FIND_TENANTS_BY_CLASSIFICATION_CODE,
} from 'api/queries/tenantQueries';
import { FIND_ONE_COMPANY_CLASSIFICATIN_CODE } from 'api/queries/classificationCodeQueries';

const filterKeyMap = {
	department: 'departmentId',
	accountant: 'accountantId',
	projectManager: 'projectManagerId',
	accountManager: 'accountManagerId',
	payrollManager: 'payrollManagerId',
	annualAccountsManager: 'annualAccountsManagerId',
	controller: 'controllerId',
	auditor: 'auditorId',
	accountType: 'accountType',
	company: 'company',
};

export default function CompanyClassificationCodesStatistics() {
	return (
		<DesktopLayout
			title="Statistikk næringskoder"
			breadcrumbs={[
				{
					to: '/statistikk/',
					label: 'Statistikk',
				},
				{
					label: 'Næringskoder',
				},
			]}
		>
			<ContentWithSidebarFilters
				displayFilters={[
					'department',
					'accountant',
					'projectManager',
					'accountManager',
					'payrollManager',
					'annualAccountsManager',
					'controller',
					'auditor',
					'accountType',
					'company',
				]}
			>
				<CompanyClassificationCodesStatisticsContent />
			</ContentWithSidebarFilters>
		</DesktopLayout>
	);
}

function CompanyClassificationCodesStatisticsContent() {
	const { activeFilters } = useAppFilters();

	const {
		data: { classifications } = { classifications: [] },
		loading,
		error,
	} = useQuery(GET_TOTAL_COMPANY_CLASSIFICATION_COUNTS, {
		variables: {
			filter: renameFilterKeys(activeFilters, filterKeyMap),
		},
	});

	if (loading) {
		return <InlineLoading />;
	}

	return (
		<div>
			<h1>Statistikk næringskoder</h1>

			<ErrorAlert error={error} />

			<Classifications classifications={classifications} />
		</div>
	);
}

function Classifications({ classifications }) {
	if (!classifications || classifications.length === 0) {
		return <div>Ingen data</div>;
	}

	const grouped = classifications.reduce((acc, classification) => {
		const { code, name, count } = classification;

		const groupCode = code.split('.')[0];

		if (!acc[groupCode]) {
			acc[groupCode] = {
				code: groupCode,
				total: 0,
				items: [],
			};
		}

		acc[groupCode].items.push({ code, name, count });

		acc[groupCode].total += count;

		return acc;
	}, {});

	const groupedClassifications = Object.values(grouped).sort((a, b) =>
		a.code.localeCompare(b.code)
	);

	return (
		<div>
			{groupedClassifications.map(group => (
				<ClassificationGroup key={group.code} group={group} />
			))}
		</div>
	);
}

const ClassificationGroupWrapper = styled.div`
	background-color: ${p => p.theme.palette.background.paper};
	margin-bottom: 5px;
	padding: 10px 20px;
`;

const ClassificationGroupHeader = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 10px;
	text-align: left;
	width: 100%;
`;

const ClassificationGroupContent = styled.div`
	font-size: 0.9em;
	padding: 30px 0 10px 0;
`;

const ClassificationGroupCode = styled.div`
	font-weight: bold;
`;

const ClassificationGroupTotal = styled.div`
	opacity: ${p => (p.$visible ? 1 : 0)};
	transition: opacity 0.3s;
	text-align: center;
`;

function ClassificationGroup({ group }) {
	const [expanded, setExpanded] = useState(false);

	return (
		<ClassificationGroupWrapper>
			<ClassificationGroupHeader
				onClick={() => setExpanded(!expanded)}
				type="button"
			>
				<ClassificationGroupCode>{group.code}</ClassificationGroupCode>

				<ClassificationGroupName code={group.code} />

				<ClassificationGroupTotal $visible={!expanded}>
					{group.total}
				</ClassificationGroupTotal>
			</ClassificationGroupHeader>

			<Collapse in={expanded}>
				<ClassificationGroupContent>
					{group.items.map(classification => (
						<Classification
							key={classification.code}
							classification={classification}
						/>
					))}

					<Summary total={group.total} />
				</ClassificationGroupContent>
			</Collapse>
		</ClassificationGroupWrapper>
	);
}

const ClassificationGroupNameError = styled.div`
	color: ${p => p.theme.palette.status.error};
	flex: 1;
`;

const ClassificationGroupNameWrapper = styled.div`
	font-weight: bold;
	flex: 1;
`;

function ClassificationGroupName({ code }) {
	const {
		data: { classification } = { classification: {} },
		loading,
		error,
	} = useQuery(FIND_ONE_COMPANY_CLASSIFICATIN_CODE, {
		variables: {
			code,
		},
	});

	if (loading) {
		return (
			<ClassificationGroupNameWrapper>
				<Skeleton variant="text" sx={{ width: '20%' }} />
			</ClassificationGroupNameWrapper>
		);
	}

	if (error) {
		return (
			<ClassificationGroupNameError>
				Det oppstod en feil ved henting av næringskode!
			</ClassificationGroupNameError>
		);
	}

	if (!classification || !classification.name) {
		return (
			<ClassificationGroupNameWrapper>
				Uoppgitt
			</ClassificationGroupNameWrapper>
		);
	}

	return (
		<ClassificationGroupNameWrapper>
			{classification.name}
		</ClassificationGroupNameWrapper>
	);
}

const ClassificationWrapper = styled.div`
	border-bottom: 1px solid ${p => p.theme.palette.borderColor.default};
	margin-bottom: 8px;
	padding-bottom: 8px;
`;

const ClassificationHeader = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 10px;
	text-align: left;
	width: 100%;

	&:hover {
		color: ${p => p.theme.palette.primary.main};
	}
`;

const ClassificationCode = styled.div``;

const ClassificationName = styled.div`
	flex: 1;
`;

const ClassificationCount = styled.div``;

const ClassificationContent = styled.div`
	font-size: 0.9em;
	margin: 20px;
`;

function Classification({ classification }) {
	const [expanded, setExpanded] = useState(false);

	return (
		<ClassificationWrapper>
			<ClassificationHeader
				type="button"
				onClick={() => setExpanded(!expanded)}
			>
				<ClassificationCode>{classification.code}</ClassificationCode>

				<ClassificationName>{classification.name}</ClassificationName>

				<ClassificationCount>
					{classification.count}
				</ClassificationCount>
			</ClassificationHeader>

			<Collapse in={expanded}>
				<ClassificationContent>
					{expanded && (
						<CompaniesWithCode code={classification.code} />
					)}
				</ClassificationContent>
			</Collapse>
		</ClassificationWrapper>
	);
}

const Company = styled.div`
	margin-bottom: 5px;
`;

function CompaniesWithCode({ code }) {
	const { activeFilters } = useAppFilters();

	const {
		data: { companies } = { companies: [] },
		loading,
		error,
	} = useQuery(FIND_TENANTS_BY_CLASSIFICATION_CODE, {
		variables: {
			code,
			filter: renameFilterKeys(activeFilters, filterKeyMap),
		},
	});

	if (loading) {
		return <div>Henter bedrifter…</div>;
	}

	if (error) {
		return <ErrorAlert error={error} />;
	}

	if (!companies || companies.length === 0) {
		return <div>Ingen bedrifter</div>;
	}

	return (
		<div>
			{companies.map(company => (
				<Company key={company._id}>
					<UnderlineLink to={`/bedrifter/${company._id}/rediger`}>
						{company.name}
					</UnderlineLink>
				</Company>
			))}
		</div>
	);
}

const SummaryWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin: 30px 0 0 0;
`;

function Summary({ total }) {
	return (
		<SummaryWrapper>
			<table width="120px">
				<tbody>
					<tr>
						<td>
							<strong>Totalt</strong>
						</td>
						<td align="right">{total}</td>
					</tr>
				</tbody>
			</table>
		</SummaryWrapper>
	);
}
