import { useState } from 'react';

/**
 * Custom React hook to manage key-value pairs in a single localStorage object.
 *
 * This hook behaves like useState but stores each key-value pair in a single
 * localStorage object (e.g., 'app-settings'). You can retrieve and update individual
 * settings by their keys. Returns `defaultValue` if the key is not yet set or doesn't exist.
 *
 * @param {string} key - The key in the settings object stored in localStorage.
 * @param {any} defaultValue - The default value returned if the key is not present.
 *
 * @returns {Array} - Returns an array with two elements:
 *
 * - The current value associated with the given key, or the `defaultValue` if the key doesn't exist.
 * - A function to update the value for that key in the localStorage object.
 *
 * Example Usage:
 * ```javascript
 * const {value: theme, setValue: setTheme} = useLocalStorageSetting('theme', 'light');
 * const {value: filter, setValue: setFilter} = useLocalStorageSetting('filter', { active: false });
 *
 * // Set the theme
 * setTheme('dark');
 *
 * // Set the filter
 * setFilter({ active: true });
 * ```
 */
export default function useLocalStorageSetting(key, defaultValue = null) {
	const localStorageKey = process.env.REACT_APP_SETTINGS_KEY;

	// Initialize state from localStorage or use defaultValue if the key is not present
	const [storedValue, setStoredValue] = useState(() => {
		const storedSettings = localStorage.getItem(localStorageKey);

		const parsedSettings = storedSettings ? JSON.parse(storedSettings) : {};

		return key in parsedSettings ? parsedSettings[key] : defaultValue;
	});

	// Setter function to update both localStorage and state
	const setValue = value => {
		const storedSettings = localStorage.getItem(localStorageKey);
		const parsedSettings = storedSettings ? JSON.parse(storedSettings) : {};

		const updatedSettings = {
			...parsedSettings,
			[key]: value,
		};

		setStoredValue(value);

		localStorage.setItem(localStorageKey, JSON.stringify(updatedSettings));
	};

	const value = storedValue;

	// Return as object to be able to destructure the hook when noth using the getter or setter
	return { value, setValue };
}
