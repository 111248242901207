import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';

export default function CheckboxGroup({
	name,
	label = null,
	required = false,
	children,
	...props
}) {
	if (!children) {
		console.error('CheckboxGroup must have children of type Checkbox');

		return null;
	}

	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ fieldState: { error } }) => (
				<FormControl
					required={required}
					error={!!error}
					component="fieldset"
					variant="outlined"
				>
					{label && <FormLabel>{label}</FormLabel>}

					<FormGroup>{children}</FormGroup>

					{error && <FormHelperText>{error.message}</FormHelperText>}
				</FormControl>
			)}
			{...props}
		/>
	);
}
