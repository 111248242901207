import { useQuery } from '@apollo/client';
import { useAppFilters, actionTypes } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';
import { PAGINATE_MY_TENANTS } from 'api/queries/tenantQueries';
import ErrorAlert from 'components/ErrorAlert';

const allOption = { value: '', label: 'Alle mine bedrifter' };

export default function MyCompanyFilter({ disabled = false, size = 'medium' }) {
	const { selectedCompany, dispatch } = useAppFilters();

	const {
		data: { companies = { count: 0, items: [] } } = {
			companies: { count: 0, items: [] },
		},
		loading,
		error,
	} = useQuery(PAGINATE_MY_TENANTS, {
		variables: {
			orderBy: 'name',
			order: 1,
		},
	});

	return (
		<div>
			<ErrorAlert error={error} />
			<Filter
				name="companies-filter"
				label="Vis bedrift"
				value={selectedCompany ?? allOption}
				options={[
					allOption,
					...(companies?.items?.map(company => ({
						value: company._id,
						label: company.name,
					})) ?? []),
				]}
				onChange={value =>
					dispatch({
						type: actionTypes.SET_SELECTED_COMPANY,
						payload: value,
					})
				}
				disabled={disabled}
				size={size}
				loading={loading}
			/>
		</div>
	);
}
