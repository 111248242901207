import CompanyName from '../cells/CompanyName';
import TaskDeadlineEntryStatusInPeriod from '../cells/TaskDeadlineEntryStatusInPeriod';
import TaskDeadlineEntryStatusByType from '../cells/TaskDeadlineEntryStatusByType';
import QualityControlControlStatus from '../cells/QualityControlControlStatus';
import AnnualSettlementControlStatus from '../cells/AnnualSettlementControlStatus';
import RiskScore from '../cells/RiskScore';
import DeviationStatus from '../cells/DeviationStatus';

const defaultColumns = [
	{
		field: 'name',
		renderHeader: () => <strong>Navn</strong>,
		width: 300,
		renderCell: ({ id, row }) => <CompanyName id={id} company={row} />,
		sortable: true,
		hideable: false,
	},
	{
		field: 'skattemelding',
		headerName: 'Skattemelding',
		renderCell: ({ id }) => (
			<TaskDeadlineEntryStatusByType companyId={id} type="TAX_NOTICE" />
		),
		sortable: false,
		width: 240,
	},
	{
		field: 'arsregnskap',
		headerName: 'Årsregnskap',
		renderCell: ({ id }) => (
			<TaskDeadlineEntryStatusByType
				companyId={id}
				type="ANNUAL_ACCOUNTS"
			/>
		),

		sortable: false,
		width: 150,
	},
	{
		field: 'kvalitetskontroll',
		headerName: 'Kvalitetskontroll',
		renderCell: ({ id }) => <QualityControlControlStatus companyId={id} />,
		sortable: false,
		width: 150,
	},
	{
		field: 'arsoppgjorkontroll',
		headerName: 'Årsoppgjørkontroll',
		renderCell: ({
			id,
			row: { selfRevisionRequired, reviewedAnnualFinancialStatus },
		}) => (
			<AnnualSettlementControlStatus
				companyId={id}
				selfRevisionRequired={selfRevisionRequired}
				reviewedAnnualFinancialStatus={reviewedAnnualFinancialStatus}
			/>
		),
		sortable: false,
		minWidth: 150,
	},
	{
		field: 'hvitvasking',
		headerName: 'Hvitvasking',
		renderCell: ({ id, row: { accountTypes } }) => (
			<RiskScore companyId={id} accountTypes={accountTypes} />
		),
		sortable: false,
		minWidth: 150,
	},
	{
		field: 'avvik',
		headerName: 'Avvik',
		renderCell: ({ id, row: { dataDeviations } }) => (
			<DeviationStatus
				id={id}
				hasDeviations={dataDeviations?.hasDeviations}
			/>
		),
		sortable: false,
		width: 60,
		align: 'center',
	},
];

const getColumns = tasks => {
	let columns = [...defaultColumns];

	tasks.forEach(task => {
		columns.push({
			field: task,
			headerName: task,
			renderCell: ({ id }) => (
				<TaskDeadlineEntryStatusInPeriod companyId={id} task={task} />
			),
			sortable: false,
			minWidth: 150,
		});
	});

	return columns;
};

export default getColumns;
