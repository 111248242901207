import { escapeRegExp } from 'utils/regExp';

export function insertOne(model, options) {
	return async (_, doc, context) => {
		try {
			doc.createdAt = new Date();
			const _id = context.uuid();

			if (context.user) {
				doc.createdBy = context.user._id;
				doc.createdByDisplayName = context.user.name;
			}

			if (options && options.transformDoc) {
				doc = await options.transformDoc(_, { _id }, doc, context);
			}

			const response = await context[model].insertOne({
				_id,
				...doc,
			});

			if (!response.insertedId) {
				throw new Error('Dokumentet ble ikke lagret!');
			}

			const insertedDoc = await context[model].findOne({
				_id: response.insertedId,
			});

			if (options && options.afterInsert) {
				await options.afterInsert(_, insertedDoc, doc, context);
			}

			return insertedDoc;
		} catch (err) {
			console.error(err);

			return err;
		}
	};
}

export function updateOne(model, options) {
	return async (_, args, context) => {
		try {
			let { _id, ...doc } = args;
			let query = { _id };

			doc.updatedAt = new Date();

			if (context.user) {
				doc.updatedBy = context.user._id;
				doc.updatedByDisplayName = context.user.name;
			}

			if (options && options.transformDoc) {
				doc = await options.transformDoc(_, { _id }, doc, context);
			}

			if (options && options.transformQuery) {
				query = await options.transformQuery(_, args, query, context);
			}

			const updatedDoc = await context[model].findOneAndUpdate(
				query,
				{ $set: doc },
				{ returnDocument: 'after' }
			);

			if (!updatedDoc) {
				throw new Error('Dokumentet ble ikke funnet i databasen!');
			}

			options &&
				options.after &&
				(await options.after(_, args, query, context, updatedDoc));

			return updatedDoc;
		} catch (err) {
			console.error(err);

			return err;
		}
	};
}

export function deleteOne(model, options) {
	return async (_, args, context) => {
		try {
			let query = args;

			if (options && options.transformQuery) {
				query = await options.transformQuery(_, args, query, context);
			}

			const { deletedCount } = await context[model].deleteOne(query);

			if (deletedCount !== 1) {
				throw new Error('Dokumentet ble ikke slettet!');
			}

			return true;
		} catch (err) {
			console.error(err);

			return err;
		}
	};
}

export function softDeleteOne(model, options) {
	return async (_, args, context) => {
		try {
			let { _id } = args;
			let { user } = context;

			if (options && options.validateQuery) {
				await options.validateQuery(_, args, context);
			}

			const { modifiedCount } = await context[model].updateOne(
				{ _id },
				{
					$set: {
						isDeleted: true,
						deletedAt: new Date(),
						deletedBy: user._id,
						deletedByUserName: user.name,
					},
				}
			);

			if (modifiedCount !== 1) {
				throw new Error('Dokumentet ble ikke slettet!');
			}

			return true;
		} catch (err) {
			console.error(err);

			return err;
		}
	};
}

export function archiveOne(model, _options) {
	return async (_, args, context) => {
		try {
			let { _id } = args;
			let { user } = context;

			const { modifiedCount } = await context[model].updateOne(
				{ _id },
				{
					$set: {
						isArchived: true,
						archivedAt: new Date(),
						archivedBy: user._id,
						archivedByUserName: user.name,
					},
				}
			);

			if (modifiedCount !== 1) {
				throw new Error('Dokumentet ble ikke arkivert!');
			}

			return true;
		} catch (err) {
			console.error(err);

			return err;
		}
	};
}

export function findOne(model, options) {
	try {
		let afterFindOne =
			(options && options.afterFindOne) ||
			(async (doc, _, { _id }, _context) => {
				return doc;
			});

		return async (_, args, context) => {
			let query = args;

			if (options && options.transformQuery) {
				query = await options.transformQuery(_, args, query, context);
			}

			const doc = await context[model].findOne(query);

			return await afterFindOne(doc, _, args, context);
		};
	} catch (err) {
		console.error(err);

		return err;
	}
}

export function hasOne(model, { localKey }) {
	return async (parentNode, args, context) => {
		try {
			return await context[model].findOne({ _id: parentNode[localKey] });
		} catch (err) {
			console.error(err);

			return err;
		}
	};
}

export function find(model, options) {
	return async (
		_,
		{ limit, skip, _search, order, orderBy, ...args },
		context
	) => {
		try {
			let query = args;

			if (options && options.transformQuery) {
				query = await options.transformQuery(_, args, query, context);
			}

			const cursor = await context[model]
				.find(query)
				.collation({ locale: 'nb', caseLevel: false });

			if (orderBy) {
				await cursor.sort({ [orderBy]: order || -1 });
			}

			await cursor.skip(skip || 0).limit(limit || 0);

			return await cursor.toArray();
		} catch (err) {
			console.error(err);

			return err;
		}
	};
}

export function paginate(model, options) {
	return async (
		parentNode,
		{ limit, skip, search, order, orderBy, ...args },
		context,
		info
	) => {
		try {
			let query = {};
			let queryArgs = (options && options.queryArgs) || [];
			let selectionSetArray = [];

			info.fieldNodes[0].selectionSet.selections.map(selection =>
				selectionSetArray.push(selection.name.value)
			);

			if (search) {
				const escapedSearch = escapeRegExp(search);
				const regQ = new RegExp(escapedSearch, 'i');

				query[options.searchIndex ?? 'name'] = regQ;
			}

			queryArgs.forEach(key => {
				if (typeof args[key] == 'undefined') {
					return;
				}

				query[key] = args[key];
			});

			if (options && options.transformQuery) {
				query = await options.transformQuery(
					parentNode,
					args,
					query,
					context
				);
			}

			const cursor = await context[model]
				.find(query)
				.collation({ locale: 'nb', caseLevel: false });

			if (orderBy) {
				await cursor.sort({ [orderBy]: order || -1 });
			}

			await cursor.skip(skip || 0).limit(limit || 0);

			let count = null;
			let items = null;

			if (selectionSetArray.indexOf('count') > -1) {
				count = await context[model].countDocuments(query);
			}

			if (selectionSetArray.indexOf('items') > -1) {
				items = await cursor.toArray();
			}

			if (options && options.afterPaginate) {
				return await options.afterPaginate(
					{
						count,
						items,
					},
					parentNode,
					{ ...query, ...args },
					context,
					info
				);
			}

			return {
				count,
				items,
			};
		} catch (err) {
			console.error(err);

			return err;
		}
	};
}

export function connection(model, options) {
	return async (
		parentNode,
		{ limit, skip, search, order, orderBy, ...args },
		context
	) => {
		try {
			let query = {};
			let queryArgs = (options && options.queryArgs) || [];

			query[options.foreignKey] = parentNode[options.localKey || '_id'];

			if (search) {
				query['$search'] = {
					$search: search,
				};
			}

			queryArgs.forEach(key => {
				query[key] = args[key];
			});

			if (options.transformQuery) {
				query = await options.transformQuery(
					parentNode,
					args,
					query,
					context
				);
			}

			const cursor = await context[model].find(query);

			if (orderBy) {
				await cursor.sort({ [orderBy]: order || -1 });
			}

			await cursor.skip(skip || 0).limit(limit || 25);

			return {
				count: await context[model].countDocuments(query),
				items: await cursor.toArray(),
			};
		} catch (err) {
			console.error(err);

			return err;
		}
	};
}
