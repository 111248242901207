import { useAppFilters } from 'web/contexts/AppFiltersContext';

import TaskDeadlineEntryInPeriod from 'web/components/companiesArchive/cells/TaskDeadlineEntryInPeriod';

export default function TaskDeadlineEntryStatusInPeriod({ companyId, task }) {
	const { year, month } = useAppFilters();

	if (Number.isNaN(!year?.value) || Number.isNaN(!month?.value) || !task) {
		return null;
	}

	return (
		<TaskDeadlineEntryInPeriod
			companyId={companyId}
			taskName={task}
			month={month.value}
			year={year.value}
		/>
	);
}
