import styled from 'styled-components';
import Button from '@mui/material/Button';

import { getRoleLabel } from 'server/libs/companyRoles';

import { pluralize } from 'utils/strings';
import { conjunctionFormat } from 'utils/arrays';

import { getDepartmentLabel } from 'components/utils/departments';

import { useUser } from 'web/contexts/UserContext';

import { ShortcutLink } from 'web/components/Link';
import FavoriteButton from 'web/components/FavoriteButton';

import Icon from 'components/Icon';

import {
	Card,
	CardHeader,
	CardHeaderContent,
	CardContent,
} from 'components/GridCardLayout';

import {
	ListItemElement,
	List,
	ListItem,
	ListItemLabel,
	ListItemValue,
} from 'components/List';

const CompanyInfoHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 16px;
	padding: 32px 0;

	@media (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 1600px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;
const GridItem = styled.div``;

const Name = styled.div`
	display: flex;
	align-items: center;
	h1 {
		margin: 0;
		font-weight: 400;
		font-size: 1.6rem;
		line-height: 1.334;
		display: block;
	}
`;

export default function CompanyInfo({ company }) {
	const { userHasMinRole } = useUser();

	if (!company) return;

	const revisorFirms = company.accountingFirms
		? company.accountingFirms.filter(firm => firm.role === 'revisor')
		: null;
	const accountingFirms = company.accountingFirms
		? company.accountingFirms.filter(firm => firm.role === 'regnskapsfører')
		: null;

	return (
		<>
			<CompanyInfoHeader>
				<div>
					<Name>
						<h1>{company.name}</h1>

						<FavoriteButton company={company} />
					</Name>

					{company.freeText && <p>{company.freeText}</p>}
				</div>

				{userHasMinRole('accountant') && (
					<ShortcutLink to={'rediger/'} shortcut="F2">
						<Button
							variant="contained"
							color="primary"
							startIcon={<Icon icon="edit" />}
						>
							Firmaopplysninger
						</Button>
					</ShortcutLink>
				)}
			</CompanyInfoHeader>

			<Grid>
				<GridItem>
					<Card>
						<CardHeader>
							<CardHeaderContent>
								<Icon icon="address-book" size="xl" duotone />
							</CardHeaderContent>
						</CardHeader>

						<CardContent>
							<List>
								{company.departmentId && (
									<ListItemElement
										label="Avdeling"
										value={getDepartmentLabel(
											company.departmentId
										)}
									/>
								)}

								<Address
									heading="Forretningsadresse"
									address={company?.businessAddress}
								/>

								<Address
									heading="Postadresse"
									address={company?.postalAddress}
								/>
							</List>

							{!company?.businessAddress &&
								!company?.postalAddress && (
									<p>Ingen adresser registrert</p>
								)}
						</CardContent>
					</Card>
				</GridItem>

				<GridItem>
					<Card>
						<CardHeader>
							<CardHeaderContent>
								<Icon icon="file-lines" size="xl" duotone />
							</CardHeaderContent>
						</CardHeader>

						<CardContent>
							<List>
								{company.companyType && (
									<ListItemElement
										label="Organisasjonsform"
										value={`${company.companyType?.name} (${company.companyType?.id})`}
									/>
								)}

								{company.classifications && (
									<ListItemElement
										label="Næringskoder"
										value={company.classifications.map(
											({ code, name }) => (
												<div key={code}>
													{code} {name}
												</div>
											)
										)}
									/>
								)}

								{company.orgNo && (
									<ListItemElement
										label="Organisasjonsnr"
										value={company.orgNo}
									/>
								)}

								{company.clientNo && (
									<ListItemElement
										label="Kundenummer"
										value={company.clientNo}
									/>
								)}

								{company.signatories && (
									<ListItemElement
										label="Signatur"
										value={company.signatories.join(', ')}
									/>
								)}

								{company.procurations && (
									<ListItemElement
										label="Prokura"
										value={company.procurations.join(', ')}
									/>
								)}

								{/** Don't show this one if we have no value from the proff API. */}
								{typeof company.isVatRegistered ===
									'boolean' && (
									<ListItemElement
										label="Registrert i MVA"
										value={
											company.isVatRegistered
												? 'Ja'
												: 'Nei'
										}
									/>
								)}

								{/** Don't show this one if we have no value from the proff API. */}
								{typeof company.isAaRegistered ===
									'boolean' && (
									<ListItemElement
										label="Registrert i Aa"
										value={
											company.isAaRegistered
												? 'Ja'
												: 'Nei'
										}
									/>
								)}
							</List>
						</CardContent>
					</Card>
				</GridItem>

				<GridItem>
					<Card>
						<CardHeader>
							<CardHeaderContent>
								<Icon icon="users" size="xl" duotone />
							</CardHeaderContent>
						</CardHeader>

						<CardContent>
							<List>
								{company.accountants &&
									company.accountants.length > 0 && (
										<ListItemElement
											label={pluralize(
												'Regnskapsfører',
												'Regnskapsførere',
												company.accountants
											)}
											value={conjunctionFormat(
												company.accountants,
												['name']
											)}
										/>
									)}

								{company.accountManagers &&
									company.accountManagers.length > 0 && (
										<ListItemElement
											label={pluralize(
												'Kundeansvarlig',
												'Kundeansvarlige',
												company.accountManagers
											)}
											value={conjunctionFormat(
												company.accountManagers,
												['name']
											)}
										/>
									)}

								{company.payrollManagers &&
									company.payrollManagers.length > 0 && (
										<ListItemElement
											label={pluralize(
												'Lønnsansvarlig regnskapsfører',
												'Lønnsansvarlig regnskapsførere',
												company.payrollManagers
											)}
											value={conjunctionFormat(
												company.payrollManagers,
												['name']
											)}
										/>
									)}

								{company.annualAccountsManagers &&
									company.annualAccountsManagers.length >
										0 && (
										<ListItemElement
											label={pluralize(
												'Årsoppgjørsansvarlig',
												'Årsoppgjørsansvarlige',
												company.annualAccountsManagers
											)}
											value={conjunctionFormat(
												company.annualAccountsManagers,
												['name']
											)}
										/>
									)}

								{userHasMinRole('accountant') &&
									company.controllers &&
									company.controllers.length > 0 && (
										<ListItemElement
											label={pluralize(
												'Oppdragskontrollør',
												'Oppdragskontrollører',
												company.controllers
											)}
											value={conjunctionFormat(
												company.controllers,
												['name']
											)}
										/>
									)}

								{company.auditors &&
									company.auditors.length > 0 && (
										<ListItemElement
											label={pluralize(
												'Revisor',
												'Revisorer',
												company.auditors
											)}
											value={conjunctionFormat(
												company.auditors,
												['name']
											)}
										/>
									)}

								{accountingFirms &&
									accountingFirms.length > 0 && (
										<ListItemElement
											label="Regnskapsfirma"
											value={conjunctionFormat(
												accountingFirms,
												['name']
											)}
										/>
									)}

								{revisorFirms && revisorFirms.length > 0 && (
									<ListItemElement
										label="Revisorfirma"
										value={conjunctionFormat(revisorFirms, [
											'name',
										])}
									/>
								)}
							</List>
						</CardContent>
					</Card>
				</GridItem>

				{(company.boardMembers?.length > 0 ||
					company.owners?.length > 0) && (
					<GridItem>
						<Card>
							<CardHeader>
								<CardHeaderContent>
									<Icon icon="users" size="xl" duotone />
								</CardHeaderContent>
							</CardHeader>

							<CardContent>
								<List>
									{company.boardMembers?.length > 0 && (
										<ListItemElement
											label={pluralize(
												'Styremedlem',
												'Styremedlemer',
												company.boardMembers
											)}
											value={
												<BoardMembers
													members={
														company.boardMembers
													}
												/>
											}
										/>
									)}

									{company.owners?.length > 0 && (
										<ListItemElement
											label={pluralize(
												'Rettighetshaver',
												'Rettighetshavere',
												company.owners
											)}
											value={conjunctionFormat(
												company.owners.map(
													owner => owner.user
												),
												['name']
											)}
										/>
									)}
								</List>
							</CardContent>
						</Card>
					</GridItem>
				)}
			</Grid>
		</>
	);
}

const StreetAddress = styled.div`
	margin-bottom: 2px;
`;

const PostalAddress = styled.div``;

const PostalCode = styled.span`
	margin-right: 5px;
`;

const Locality = styled.span``;

function Address({ heading, address }) {
	if (!address) return null;

	return (
		<ListItem>
			<ListItemLabel>{heading}</ListItemLabel>

			<ListItemValue>
				<StreetAddress>{address.streetAddress}</StreetAddress>

				<PostalAddress>
					<PostalCode>{address.postalCode}</PostalCode>

					<Locality>{address.locality}</Locality>
				</PostalAddress>
			</ListItemValue>
		</ListItem>
	);
}

const BoardList = styled.ul`
	padding-left: 20px;
	list-style-type: disc;
	line-height: 1.2;

	li {
		margin-bottom: 0.5em;
	}
`;
const StyledRole = styled.em`
	margin: 0;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.43;
	display: block;
`;
const SingleBoardMember = styled.div`
	line-height: 1.2;
`;
function BoardMembers({ members }) {
	if (!members) return null;

	if (members.length === 1) {
		return (
			<SingleBoardMember>
				{members[0].name}
				<StyledRole>{getRoleLabel(members[0].userRole)}</StyledRole>
			</SingleBoardMember>
		);
	}

	return (
		<BoardList>
			{members.map((member, index) => (
				<li key={index}>
					{member.name}
					<StyledRole>{getRoleLabel(member.userRole)}</StyledRole>
				</li>
			))}
		</BoardList>
	);
}
