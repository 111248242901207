import { useEffect, useState } from 'react';

// Use session storage to save a value to make sure the value is not lost when the user refreshes the page
export default function useSessionStorage(key, initialValue = null) {
	if (!key) {
		console.error('useSessionStorage key cannot be null');
		return [initialValue, () => {}];
	}

	// Create a unique key for the session storage
	const storageKey = 'admento-falstad-' + key;

	const [value, setValue] = useState(() => {
		try {
			const savedValue = sessionStorage.getItem(storageKey);
			return savedValue !== null ? JSON.parse(savedValue) : initialValue;
		} catch (error) {
			console.error('Error parsing session storage value:', error);
			return initialValue;
		}
	});

	useEffect(() => {
		try {
			sessionStorage.setItem(storageKey, JSON.stringify(value));
		} catch (error) {
			console.error('Error setting session storage value:', error);
		}
	}, [storageKey, value]);

	// Return as object to be able to destructure the hook
	return { value, setValue };
}
