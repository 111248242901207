import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import StatusIcon from 'components/StatusIcon';
import { RiskScorePill } from 'components/Pill';

import { StyledLink } from 'web/components/Link';

import { GET_LATEST_COMPANY_RISK } from 'api/queries/companyRiskQueries';

const Error = styled.div`
	color: ${p => p.theme.palette.status.error};
`;

export default function RiskScore({ companyId, accountTypes }) {
	if (!accountTypes?.includes('accounting')) {
		return null;
	}

	return <RiskScoreContent companyId={companyId} />;
}

function RiskScoreContent({ companyId }) {
	const {
		data: { companyRisk } = { companyRisk: null },
		loading,
		error,
	} = useQuery(GET_LATEST_COMPANY_RISK, {
		variables: {
			companyId,
		},
	});

	if (loading) return null;

	if (error) {
		console.error(error);

		return <Error>Feil</Error>;
	}

	const link = companyRisk
		? `/bedrifter/${companyId}/hvitvasking/${companyRisk._id}/rediger/`
		: `/bedrifter/${companyId}/rediger/?tab=hvitvasking`;

	return (
		<StyledLink to={link}>
			<RiskScorePill $score={companyRisk?.totalScore}>
				{companyRisk?.totalScore ?? 'Ikke vurdert'}
			</RiskScorePill>

			<StatusIcon
				error={companyRisk?.needsRevision ?? true}
				errorTitle="Hvitvaskingsskjema trenger revisjon!"
				successTitle="Hvitvaskingsskjema er OK!"
			/>
		</StyledLink>
	);
}
