import { useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';

import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';

import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';

import Form, { Checkbox, CheckboxGroup } from 'web/form/Form';
import ErrorMessage from 'components/ErrorMessage';

import { RESEND_SIGNING_REQUEST } from 'api/mutations/signatureMutations';
import { useSnackbar } from 'web/contexts/SnackbarContext';

export default function SignatureReminderModal({
	signee,
	documentId,
	open,
	onClose,
	onSuccess = () => {},
}) {
	const { notify } = useSnackbar();

	const [resendSigningRequest, { error: resendSigningRequestError }] =
		useMutation(RESEND_SIGNING_REQUEST);

	const { userId, userName, email, phone } = signee;
	const name = userName || email || phone;

	const onSubmit = async ({ notifyEmail, notifySMS }, { setError }) => {
		if (!notifyEmail && !notifySMS) {
			setError('checkboxes', {
				type: 'manual',
				message: 'Minst ett av alternativene må være valgt',
			});
			return;
		}

		try {
			const { data } = await resendSigningRequest({
				variables: {
					documentId,
					signee: {
						email: notifyEmail ? email : '',
						phone: notifySMS ? phone : '',
						userId: userId,
					},
					notifyEmail,
					notifySMS,
				},
			});

			if (data) {
				notify(`Påminnelse til ${name} sendt!`);
				onSuccess();
				onClose();
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="xs"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Send påminnelse om signering til <br />
				<strong>{name}</strong>
			</DialogTitle>

			<DialogCloseButton onClick={onClose} />

			<Form
				values={{
					notifyEmail: !!email || !!userId,
					notifySMS: !!phone || !!userId,
				}}
				onSubmit={onSubmit}
			>
				<DialogContent>
					<ErrorMessage errors={[resendSigningRequestError]} />

					<Checkboxes phone={phone} email={email} userId={userId} />
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Avbryt</Button>

					<Button
						type="submit"
						startIcon={<SendIcon />}
						variant="contained"
					>
						Send påminnelse
					</Button>
				</DialogActions>
			</Form>
		</Dialog>
	);
}

const Checkboxes = ({ phone, email, userId }) => {
	const { clearErrors } = useFormContext();

	return (
		<CheckboxGroup name="checkboxes" label="Velg varslingsmetode:">
			<Stack direction="row" spacing={2}>
				<Checkbox
					name="notifyEmail"
					label="E-post"
					onFocus={() => clearErrors()}
					disabled={!email && !userId}
				/>

				<Checkbox
					name="notifySMS"
					label="SMS"
					onFocus={() => clearErrors()}
					disabled={!phone && !userId}
				/>
			</Stack>
		</CheckboxGroup>
	);
};
