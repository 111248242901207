import { gql } from '@apollo/client';

export const INSERT_ONE_COMPANY_CONTRACT = gql`
	mutation insertOneCompanyContract(
		$companyId: ID!
		$startDate: DateTime!
		$type: ContractType!
	) {
		contract: insertOneCompanyContract(
			companyId: $companyId
			startDate: $startDate
			type: $type
		) {
			_id
		}
	}
`;

export const UPDATE_ONE_COMPANY_CONTRACT = gql`
	mutation updateOneCompanyContract(
		$_id: ID!
		$startDate: DateTime
		$introText: String
		$clientContactId: ID
		$signingIds: [ID]
		$services: [ServiceInput]
		$assignments: [AssignmentInput]
		$attestationRoutines: [AttestationRoutineInput]
		$software: [SoftwareInput]
		$hourlyRates: [RateInput]
		$fixedRates: [RateInput]
	) {
		contract: updateOneCompanyContract(
			_id: $_id
			startDate: $startDate
			introText: $introText
			clientContactId: $clientContactId
			signingIds: $signingIds
			services: $services
			assignments: $assignments
			attestationRoutines: $attestationRoutines
			software: $software
			hourlyRates: $hourlyRates
			fixedRates: $fixedRates
		) {
			_id
		}
	}
`;

export const DUPLICATE_ONE_COMPANY_CONTRACT = gql`
	mutation duplicateOneCompanyContract($_id: ID!) {
		contract: duplicateOneCompanyContract(_id: $_id) {
			_id
		}
	}
`;

export const DELETE_ONE_COMPANY_CONTRACT = gql`
	mutation deleteOneCompanyContract($_id: ID!) {
		deleted: deleteOneCompanyContract(_id: $_id)
	}
`;

export const SEND_COMPANY_CONTRACT_TO_SIGNING = gql`
	mutation sendCompanyContractToSigning(
		$_id: ID!
		$notifyEmail: Boolean
		$notifySMS: Boolean
	) {
		ok: sendCompanyContractToSigning(
			_id: $_id
			notifyEmail: $notifyEmail
			notifySMS: $notifySMS
		)
	}
`;

export const SEND_COMPANY_CONTRACT_SIGNING_REQUEST_NOTIFICATION = gql`
	mutation sendCompanyContractSigingRequestNotification(
		$_id: ID!
		$signingUserId: ID!
		$notifyEmail: Boolean
		$notifySMS: Boolean
	) {
		ok: sendCompanyContractSigingRequestNotification(
			_id: $_id
			signingUserId: $signingUserId
			notifyEmail: $notifyEmail
			notifySMS: $notifySMS
		)
	}
`;
