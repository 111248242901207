import { gql } from '@apollo/client';

export const GET_COMPANY_CONTROLS = gql`
	query getCompanyControls($companyId: ID!) {
		controls: getCompanyControls(companyId: $companyId) {
			_id
			name
			year
			companyId
			type {
				value
				label
			}
			status {
				code
				label
				updatedAt
				updatedBy
				updatedByDisplayName
			}
		}
	}
`;

export const GET_COMPANY_CONTROL_TYPES = gql`
	query getCompanyControlTypes {
		controlTypes: getCompanyControlTypes {
			label
			value
		}
	}
`;

export const GET_ONE_COMPANY_CONTROL = gql`
	query findOneCompanyControl($_id: ID!) {
		control: findOneCompanyControl(_id: $_id) {
			_id
			name
			year
			status {
				code
				label
				updatedAt
				updatedBy
				updatedByDisplayName
			}
			companyId
			categories {
				name
			}
			createdAt
			createdBy
			createdByDisplayName
			type {
				value
				label
			}
			updatedAt
			updatedBy
			updatedByDisplayName
		}
	}
`;

export const GET_COMPANY_CONTROL_FOR_COMPANY = gql`
	query getCompanyControlForCompany(
		$companyId: ID!
		$controlTypeValue: String!
		$year: Int
	) {
		control: getCompanyControlForCompany(
			companyId: $companyId
			controlTypeValue: $controlTypeValue
			year: $year
		) {
			_id
			name
			year
			status {
				code
				label
				updatedAt
				updatedBy
				updatedByDisplayName
			}
		}
	}
`;

export const GET_CATEGORY_QUESTIONS = gql`
	query getCategoryQuestions($controlId: ID!, $categoryName: String!) {
		questions: getCategoryQuestions(
			controlId: $controlId
			categoryName: $categoryName
		) {
			_id
			label
			value
			help
			group
			comments {
				id
				message
				createdAt
				createdBy
				createdByDisplayName
			}
			deviations {
				id
				description
				type
				typeLabel
				completed
				approved
				comments {
					id
					message
					createdAt
					createdBy
					createdByDisplayName
				}
			}
		}
	}
`;

export const GET_QUESTIONS_WITH_COMMENTS_OR_DEVIATIONS = gql`
	query getQuestionsWithCommentsOrDeviations($controlId: ID!) {
		questions: getQuestionsWithCommentsOrDeviations(controlId: $controlId) {
			_id
			label
			value
			categoryName
			help
			group
			comments {
				id
				message
				createdAt
				createdBy
				createdByDisplayName
			}
			deviations {
				id
				description
				type
				typeLabel
				completed
				approved
				comments {
					id
					message
					createdAt
					createdBy
					createdByDisplayName
				}
			}
		}
	}
`;

export const GET_CUSTOM_QUESTIONS = gql`
	query getCustomQuestions($controlId: ID!) {
		questions: getCustomQuestions(controlId: $controlId) {
			_id
			label
			value
			categoryName
			help
			comments {
				id
				message
				createdAt
				createdBy
				createdByDisplayName
			}
			deviations {
				id
				description
				type
				typeLabel
				completed
				approved
				comments {
					id
					message
					createdAt
					createdBy
					createdByDisplayName
				}
			}
		}
	}
`;
