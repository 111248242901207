import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import cloneDeep from 'lodash/cloneDeep';
import omitDeep from 'omit-deep';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import ButtonGroup from 'web/form/ButtonGroup';
import Checkbox from 'web/form/Checkbox';
import CheckboxGroup from 'web/form/CheckboxGroup';
import DatePicker from 'web/form/DatePicker';
import FormGroup from 'web/form/FormGroup';
import MultiSelect from 'web/form/MultiSelect';
import AutoComplete from 'web/form/AutoComplete';
import Select from 'web/form/Select';
import Switch from 'web/form/Switch';
import TextField from 'web/form/TextField';
import MarkdownField from 'web/form/MarkdownField';

export {
	ButtonGroup,
	Button,
	Checkbox,
	CheckboxGroup,
	DatePicker,
	FormGroup,
	MultiSelect,
	AutoComplete,
	Select,
	Switch,
	TextField,
	MarkdownField,
};

/**
 * Remove TypeDefs from GraphQL objects.
 *
 * Apollo InMemoryCache is deep freezing results,
 * so we need to clone the object before we can delete a property.
 */
function removeGraphQLTypeDefs(obj) {
	return omitDeep(cloneDeep(obj), '__typename');
}

export default function Form({
	onSubmit,
	children,
	schema = null,
	values,
	showErrors = true,
	formProps,
	...rest
}) {
	const methods = useForm({
		resolver: schema ? yupResolver(schema) : null,
		values: removeGraphQLTypeDefs(values),
		...formProps,
	});

	const {
		formState: { errors },
	} = methods;

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(values =>
					onSubmit(values, methods)
				)}
				{...rest}
			>
				<Stack spacing={3}>
					{showErrors && Object.keys(errors).length > 0 && (
						<>
							<Alert severity="error">
								<AlertTitle>
									Skjemaet inneholder feil!
								</AlertTitle>

								<p>
									Vennligst rett opp i feil og send inn
									skjemaet på nytt.
								</p>
							</Alert>
						</>
					)}

					<div>{children}</div>
				</Stack>
			</form>
		</FormProvider>
	);
}
