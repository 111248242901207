import styled from 'styled-components';

import Icon from 'components/Icon';

const StyledIcon = styled(Icon)`
	margin-left: 10px;
	vertical-align: middle;
`;

export default function SortIcon({ field, orderBy, order }) {
	if (field !== orderBy) return null;

	const getIcon = () => {
		if (order === 1) return 'sort-amount-up';
		if (order === -1) return 'sort-amount-down';
		return 'bars-sort';
	};

	return <StyledIcon icon={getIcon()} />;
}
