import { useState } from 'react';
import { useQuery } from '@apollo/client';

import theme from 'web/theme/theme';

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import UnsignedDocumentsDialog from 'web/components/widgets/dialogs/DocumentsDialog';

import {
	WidgetListItem,
	WidgetListLabel,
} from 'web/components/widgets/components/WidgetList';

import ErrorAlert from 'components/ErrorAlert';

import { COMPANY_DOCUMENTS_WITH_ACTIVE_SIGNING_REQUESTS } from 'api/queries/widgetQueries';

export default function CompanyDocumentsWidget() {
	const { department, accountantLegacy, accountantRole, company } =
		useAppFilters();

	const [showDialog, setShowDialog] = useState(false);

	const {
		data: { documents } = {
			documents: [],
		},
		error,
		loading,
	} = useQuery(COMPANY_DOCUMENTS_WITH_ACTIVE_SIGNING_REQUESTS, {
		variables: {
			filter: {
				departmentId: department?.value,
				accountantId: accountantLegacy?.value,
				accountantRole: accountantRole?.value,
				company: company?.value,
			},
		},
	});

	return (
		<>
			<ErrorAlert error={error} />

			<WidgetListItem
				label={
					<WidgetListLabel
						label="Aktive signeringer"
						chipColor={theme.palette.status.warning}
					/>
				}
				value={documents?.length ?? 0}
				loading={loading}
				onClick={() => setShowDialog(true)}
			/>

			<UnsignedDocumentsDialog
				documents={documents}
				open={showDialog}
				onClose={() => setShowDialog(false)}
			/>
		</>
	);
}
