import { paginate, findOne, insertOne, deleteOne } from 'server/libs/resolvers';

import { generateToken } from 'utils/tokens';

import {
	defaultServices,
	defaultAssignments,
	defaultSoftware,
	defaultHourlyRates,
	defaultFixedRates,
	defaultAttestationRoutines,
} from 'server/templates/contracts';

export const contractTypes = {
	AGREEMENT: 'Oppdragsavtale',
	ADDITIONAL_AGREEMENT: 'Tilleggsavtale',
};

const CompanyContractsModule = {
	typeDefs: /* GraphQL */ `
		type CompanyContract inherits Document {
			type: ContractType!
			typeLabel: String!
			companyId: ID!
			company: Tenant!
			startDate: DateTime!
			introText: String
			clientContactId: ID
			clientContact: User
			signingIds: [ID]
			signingUsers: [SigningUser]
			mySignatureUrl: String
			services: [Service]
			assignments: [Assignment]
			attestationRoutines: [AttestationRoutine]!
			software: [Software]!
			hourlyRates: [Rate]!
			fixedRates: [Rate]!
			isSentToSigning: Boolean!
			isFullySigned: Boolean!
		}

		enum ContractType {
			AGREEMENT
			ADDITIONAL_AGREEMENT
		}

		type SigningUser {
			_id: ID!
			name: String!
			email: String
			phone: String
			accessKey: String!
			signed: Boolean!
			userSub: String
			signedAt: DateTime
		}

		type Service {
			name: String!
			billings: [String]!
			fixedPrice: String
		}

		input ServiceInput {
			name: String!
			billings: [String]
			fixedPrice: String
		}

		type Assignment {
			name: String!
			comment: String
			tasks: [Task]
		}

		type Task {
			name: String!
			frequency: String
			clientDeadline: String
			contractorDeadline: String
			active: Boolean!
		}

		input AssignmentInput {
			name: String!
			comment: String
			tasks: [TaskInput]
		}

		input TaskInput {
			name: String!
			frequency: String
			clientDeadline: String
			contractorDeadline: String
			active: Boolean!
		}

		type AttestationRoutine {
			name: String!
			active: Boolean!
		}

		input AttestationRoutineInput {
			name: String!
			active: Boolean!
		}

		type Software {
			name: String!
			vendor: String
			license: String
			adminRights: Boolean
			modules: [SoftwareModule]
		}

		input SoftwareInput {
			name: String!
			vendor: String
			license: String
			adminRights: Boolean
			modules: [SoftwareModuleInput]
		}

		type SoftwareModule {
			name: String!
			unit: String!
			price: String!
			active: Boolean!
		}

		input SoftwareModuleInput {
			name: String!
			unit: String!
			price: String!
			active: Boolean!
		}

		type Rate {
			name: String!
			value: String!
			active: Boolean!
		}

		input RateInput {
			name: String!
			value: String!
			active: Boolean!
		}

		extend type Query {
			paginateCompanyContracts(
				companyId: ID!
				isSentToSigning: Boolean
				skip: Int
				limit: Int
				search: String
				order: Int
				orderBy: String
			): Paginate<CompanyContract> @auth(scope:"companyContracts:read")

			findOneCompanyContract(_id: ID!): CompanyContract @auth(scope:"companyContracts:read")

			findOnePublicCompanyContract(_id: ID!, accessKey: String): CompanyContract
		}

		extend type Mutation {
			insertOneCompanyContract(
				companyId: ID!
				startDate: DateTime!
				type: ContractType!
			): CompanyContract @auth(scope:"companyContracts:write")

			updateOneCompanyContract(
				_id: ID!
				startDate: DateTime
				introText: String
				clientContactId: ID
				signingIds: [ID]
				services: [ServiceInput]
				assignments: [AssignmentInput]
				attestationRoutines: [AttestationRoutineInput]
				software: [SoftwareInput]
				hourlyRates: [RateInput]
				fixedRates: [RateInput]
			): CompanyContract @auth(scope:"companyContracts:write")

			duplicateOneCompanyContract(
				_id: ID!
			): CompanyContract @auth(scope:"companyContracts:write")

			deleteOneCompanyContract(
				_id: ID!
			): Boolean @auth(scope:"companyContracts:write")

			sendCompanyContractToSigning(
				_id: ID!, notifyEmail: Boolean, notifySMS: Boolean
			): Boolean @auth(scope:"companyContracts:write")

			addSignatureToCompanyContract(_id: ID!, accessKey: String!, userSub: String!): Boolean

			sendCompanyContractSigingRequestNotification(_id: ID!, signingUserId: ID!, notifyEmail: Boolean, notifySMS: Boolean): Boolean @auth(scope:"companyContracts:write")
		}
	`,
	resolvers: {
		CompanyContract: {
			typeLabel: ({ type }, _args, _context) => {
				return contractTypes[type] ?? 'Ukjent kontraktssype';
			},
			company: async ({ companyId }, _args, { Tenants }) => {
				try {
					if (!companyId) return null;

					const company = await Tenants.findOne({
						_id: companyId,
					});

					if (!company) {
						throw new Error('Fant ikke tilhørende bedrift!');
					}

					return company;
				} catch (err) {
					console.error(err);

					return err;
				}
			},
			clientContact: async ({ clientContactId }, _args, { Users }) => {
				if (!clientContactId) return null;

				return await Users.findOne({ _id: clientContactId });
			},
			mySignatureUrl: ({ _id, signingUsers }, _args, { user }) => {
				if (!signingUsers) return null;

				const mySignatureRequest = signingUsers.find(
					signingUser => signingUser._id === user._id
				);

				if (mySignatureRequest.length === 0) return null;

				return `${process.env.REACT_APP_CONTRACT_SIGN_URL}/avtaler/${_id}/?tilgangsnokkel=${mySignatureRequest.accessKey}`;
			},
			services: ({ services }, _args, _context) => {
				if (!services) return defaultServices;

				return services;
			},
			assignments: ({ assignments }, _args, _context) => {
				if (!assignments) return defaultAssignments;

				return assignments;
			},
			attestationRoutines: ({ attestationRoutines }, _args, _context) => {
				if (!attestationRoutines) return defaultAttestationRoutines;

				return attestationRoutines;
			},
			software: ({ software }, _args, _context) => {
				if (!software) return defaultSoftware;

				return software;
			},
			hourlyRates: ({ hourlyRates }, _args, _context) => {
				if (!hourlyRates) return defaultHourlyRates;

				return hourlyRates;
			},
			fixedRates: ({ fixedRates }, _args, _context) => {
				if (!fixedRates) return defaultFixedRates;

				return fixedRates;
			},
			isSentToSigning: ({ isSentToSigning }, _args, _context) => {
				if (!isSentToSigning) return false;

				return isSentToSigning;
			},
			isFullySigned: ({ signingUsers }, _args, _context) => {
				if (!signingUsers || signingUsers.length === 0) return false;

				const signedUsers = signingUsers.filter(user => user.signed);

				if (signedUsers.length === signingUsers.length) return true;

				return false;
			},
		},
		Query: {
			paginateCompanyContracts: paginate('CompanyContracts', {
				queryArgs: ['companyId', 'isSentToSigning'],
			}),
			findOneCompanyContract: findOne('CompanyContracts'),
			findOnePublicCompanyContract: findOne('CompanyContracts', {
				transformQuery: async (
					_,
					{ _id, accessKey },
					query,
					_context
				) => {
					if (!accessKey) return query;

					return {
						_id,
						signingUsers: { $elemMatch: { accessKey } },
					};
				},
			}),
		},
		Mutation: {
			insertOneCompanyContract: insertOne('CompanyContracts'),
			updateOneCompanyContract: async (
				_,
				args,
				{ user, CompanyContracts, Users }
			) => {
				try {
					const { _id } = args;

					const contract = await CompanyContracts.findOne({ _id });

					if (!contract) {
						throw new Error('Fant ikke kontrakt!');
					}

					if (contract.isSentToSigning) {
						throw new Error(
							'Du kan ikke oppdatere en oppdragsavtale som allerede er sendt til signering!'
						);
					}

					let signingUsers = [];

					if (args.signingIds) {
						signingUsers = await Promise.all(
							args.signingIds.map(async id => {
								const user = await Users.findOne({
									_id: id,
								});

								if (!user) {
									throw new Error(
										'Fant ikke bruker som skal signere i databasen!'
									);
								}

								return {
									_id: user._id,
									name: user.name,
									email: user?.email,
									phone: user?.phone,
									accessKey: await generateToken(),
									signed: false,
								};
							})
						);
					}

					const updatedContract =
						await CompanyContracts.findOneAndUpdate(
							{
								_id,
							},
							{
								$set: {
									...args,
									signingUsers,
									updatedAt: new Date(),
									updatedBy: user._id,
									updatedByDisplayName: user.name,
								},
							},
							{ returnDocument: 'after' }
						);

					if (!updatedContract) {
						throw new Error(
							'Feil ved oppdatering av oppdragsavtale!'
						);
					}

					return updatedContract;
				} catch (err) {
					console.error(err);

					return err;
				}
			},
			duplicateOneCompanyContract: async (
				_,
				{ _id },
				{ CompanyContracts, user, uuid }
			) => {
				try {
					const existingContract = await CompanyContracts.findOne({
						_id,
					});

					if (!existingContract) {
						throw new Error('Fant ikke eksisterende kontrakt!');
					}

					if (!existingContract.isSentToSigning) {
						throw new Error(
							'Du kan ikke duplisere en oppdragsavtale som ikke er sendt til signering!'
						);
					}

					// Duplicate the existing contract and set new values
					const newContract = {
						...existingContract,
						_id: uuid(),
						isSentToSigning: false,
						createdAt: new Date(),
						createdBy: user._id,
						createdByDisplayName: user.name,
					};

					const { insertedId } = await CompanyContracts.insertOne({
						...newContract,
					});

					if (!insertedId) {
						throw new Error('Feil ved lagring til databasen!');
					}

					const { deletedCount } = await CompanyContracts.deleteOne({
						_id: existingContract._id,
					});

					if (deletedCount !== 1) {
						throw new Error(
							'Feil ved sletting av eksisterende kontrakt!'
						);
					}

					return await CompanyContracts.findOne({
						_id: insertedId,
					});
				} catch (err) {
					console.error(err);

					return err;
				}
			},
			deleteOneCompanyContract: deleteOne('CompanyContracts'),
			sendCompanyContractToSigning: async (
				_parent,
				{ _id, notifyEmail, notifySMS },
				{
					CompanyContracts,
					Tenants,
					notificationsQueue,
					NOTIFICATIONS_TYPE,
				}
			) => {
				try {
					const contract = await CompanyContracts.findOne({
						_id,
					});

					if (!contract) {
						throw new Error('Fant ikke kontrakt!');
					}

					if (
						!contract.signingUsers ||
						contract.signingUsers.length === 0
					) {
						throw new Error(
							'Du må først velge noen til å signere oppdragsavtalen!'
						);
					}

					const company = await Tenants.findOne({
						_id: contract.companyId,
					});

					const companyName =
						company?.name ?? '[Bedriftsnavn mangler]';

					for (const user of contract.signingUsers) {
						if (notifyEmail && !user.email) {
							throw new Error(
								`${user.name} må ha en e-postadresse for å kunne signere oppdragsavtalen!`
							);
						}

						if (notifySMS && !user.phone) {
							throw new Error(
								`${user.name} må ha et telefonnummer for å kunne signere oppdragsavtalen!`
							);
						}

						await notificationsQueue.add({
							type: NOTIFICATIONS_TYPE.SEND_CONTRACT_SIGNING_REQUEST,
							user,
							companyName,
							signUrl: `${process.env.REACT_APP_CONTRACT_SIGN_URL}/avtaler/${contract._id}/?tilgangsnokkel=${user.accessKey}`,
							notifyEmail,
							notifySMS,
						});
					}

					const { modifiedCount } = await CompanyContracts.updateOne(
						{ _id },
						{ $set: { isSentToSigning: true } }
					);

					if (modifiedCount !== 1) {
						throw new Error(
							'Feil ved sending av oppdragsavtale til signering!'
						);
					}

					return true;
				} catch (err) {
					console.error(err);

					return err;
				}
			},
			addSignatureToCompanyContract: async (
				_parent,
				{ _id, accessKey, userSub },
				{ CompanyContracts }
			) => {
				try {
					const contract = await CompanyContracts.findOne({
						_id,
						signingUsers: { $elemMatch: { accessKey } },
					});

					if (!contract) {
						throw new Error('Fant ikke kontrakt!');
					}

					if (!contract.isSentToSigning) {
						throw new Error(
							'Kontrakten må først sendes til signering før man kan signere!'
						);
					}

					const signingUser = contract.signingUsers.find(
						user => user.accessKey === accessKey
					);

					if (!signingUser) {
						throw new Error('Fant ikke bruker som skal signere!');
					}

					if (signingUser.signed) {
						throw new Error('Bruker har allerede signert!');
					}

					const { modifiedCount } = await CompanyContracts.updateOne(
						{ _id, 'signingUsers.accessKey': accessKey },
						{
							$set: {
								'signingUsers.$.signed': true,
								'signingUsers.$.userSub': userSub,
								'signingUsers.$.signedAt': new Date(),
							},
						}
					);

					if (modifiedCount !== 1) {
						throw new Error('Feil ved oppdatering av signatur!');
					}

					return true;
				} catch (err) {
					console.error(err);

					return err;
				}
			},
			sendCompanyContractSigingRequestNotification: async (
				_,
				{ _id, signingUserId, notifyEmail, notifySMS },
				{
					CompanyContracts,
					Tenants,
					notificationsQueue,
					NOTIFICATIONS_TYPE,
				}
			) => {
				const contract = await CompanyContracts.findOne({
					_id,
				});

				if (!contract) {
					throw new Error('Fant ikke kontrakt!');
				}

				if (!contract.isSentToSigning) {
					throw new Error(
						'Oppdragsavtalen må først sendes til signering!'
					);
				}

				const user = contract?.signingUsers?.find(
					user => user._id === signingUserId
				);

				if (!user) {
					throw new Error('Fant ikke bruker som skal signere!');
				}

				if (user.signed) {
					throw new Error('Bruker har allerede signert!');
				}

				if (notifyEmail && !user.email) {
					throw new Error(
						`${user.name} må ha en e-postadresse for å kunne signere oppdragsavtalen!`
					);
				}

				if (notifySMS && !user.phone) {
					throw new Error(
						`${user.name} må ha et telefonnummer for å kunne signere oppdragsavtalen!`
					);
				}

				const company = await Tenants.findOne({
					_id: contract.companyId,
				});

				const companyName = company?.name ?? '[Bedriftsnavn mangler]';

				await notificationsQueue.add({
					type: NOTIFICATIONS_TYPE.SEND_CONTRACT_SIGNING_REQUEST,
					user,
					companyName,
					signUrl: `${process.env.REACT_APP_CONTRACT_SIGN_URL}/avtaler/${contract._id}/?tilgangsnokkel=${user.accessKey}`,
					notifyEmail,
					notifySMS,
				});

				return true;
			},
		},
	},
};

export default CompanyContractsModule;
