import { useRef, useMemo } from 'react';
/*
 * This hook is to be used with a @mui/x-data-grid
 * It is used to keep the row count stable when the total count changes
 * between server-side paginated requests.
 *
 * @param {number} totalCount - The total number of rows in the table.
 * @param {number} pageSize - The number of rows per page.
 * @returns {number} The stable row count.
 */

const useStableRowCount = ({ totalCount, pageSize }) => {
	const rowCountRef = useRef(totalCount || pageSize || 0);

	const rowCount = useMemo(() => {
		if (totalCount !== undefined) {
			rowCountRef.current = totalCount;
		}
		return rowCountRef.current;
	}, [totalCount]);

	return rowCount;
};

export default useStableRowCount;
