import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

import { Card, CardContent } from 'components/GridCardLayout';

import NotVerifiedUserInfoItem from 'web/components/panels/NotVerifiedUserInfoItem';
import { InfoTitle } from 'web/components/panels/PanelItems';
import Panel from 'web/components/panels/Panel';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const Message = styled.div`
	font-size: 1rem;
`;

const StyledUl = styled.ul`
	padding-left: 1.5em;
	font-size: 16px;
`;

const StyledList = styled(List)`
	padding: 0;
	margin: 0;

	a {
		color: inherit;
	}

	li[role='separator']:last-child {
		display: none;
	}
`;

export default function GeneralDeviations({ deviations, proffDeviations }) {
	const {
		hasDeviations,
		companyUsersAndOwnersNotVerified,
		...listDeviations
	} = deviations;

	// Check if any deviations are true
	const showListDeviations = Object.values(listDeviations).some(
		value => value === true
	);

	return (
		<Panel title="Avvik" background={false}>
			<Wrapper>
				{proffDeviations?.hasDeviations && <HasProffDeviations />}

				{hasDeviations && (
					<>
						{showListDeviations && (
							<ListDeviations deviations={listDeviations} />
						)}

						{companyUsersAndOwnersNotVerified && (
							<UnverifiedUsers
								users={companyUsersAndOwnersNotVerified}
							/>
						)}
					</>
				)}
			</Wrapper>
		</Panel>
	);
}

function HasProffDeviations() {
	return (
		<Alert severity="warning">
			<div>
				<InfoTitle>Avvik fra Proff</InfoTitle>

				<Message>Se egen fane for avvik fra Proff.no</Message>
			</div>
		</Alert>
	);
}

// Messages for each deviation
const messages = {
	missingClientNumber: 'Kundenummer',
	missingOrgNumber: 'Organisasjonsnummer',
	missingProffData: 'Data fra Proff',
	oldProffData: 'Data fra Proff er mer enn 6 måneder gammel',
	missingDepartment: 'Avdeling',
	missingAccountants: 'Regnskapsfører',
	missingProjectManagers: 'Oppdragsansvarlig',
	missingAccountManagers: 'Kundeansvarlig',
	missingControllers: 'Oppdragskontrollør',
	missingAdvisors: 'Rådgiver',
	missingSignedContract: 'Oppdragsavtale mangler',
	missingCompanyAssignmentTasks: 'Oppdrag ikke planlagt',
	routinesNeedsRevision: 'Bedriftsrutiner trenger revisjon',
	assignmentRisksNeedsRevision: 'Oppdragsrisiko trenger revisjon',
};

// Helper function to render deviation list items
const renderDeviationListItem = ({ key, show, text }) => {
	return show && <li key={`deviation-item-${key}`}>{text}</li>;
};

function ListDeviations({ deviations }) {
	return (
		<Alert severity="warning">
			<div>
				<InfoTitle>Bedriften Mangler</InfoTitle>

				<StyledUl>
					{Object.entries(messages).map(([key, value]) =>
						renderDeviationListItem({
							key: key,
							show: deviations[key],
							text: value,
						})
					)}
				</StyledUl>
			</div>
		</Alert>
	);
}

function UnverifiedUsers({ users }) {
	return (
		<Card type="warning">
			<CardContent>
				<InfoTitle>Brukere som ikke er verifisert</InfoTitle>

				<StyledList>
					{users.map(user => (
						<UnverifiedUserItem
							key={`company-user-not-verified-${user._id}`}
							user={user}
						/>
					))}
				</StyledList>
			</CardContent>
		</Card>
	);
}
function UnverifiedUserItem({ user }) {
	return (
		<>
			<ListItem>
				<NotVerifiedUserInfoItem user={user} />
			</ListItem>
			<Divider component="li" />
		</>
	);
}
