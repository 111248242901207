import { StyledLink } from 'web/components/Link';
import StatusIcon from 'components/StatusIcon';

export default function DeviationStatus({ id, hasDeviations }) {
	return (
		<StyledLink to={`/bedrifter/${id}/rediger/`}>
			<StatusIcon
				error={hasDeviations}
				errorTitle="Bedriften har avvik!"
				successTitle="Bedriften har ingen avvik!"
			/>
		</StyledLink>
	);
}
