import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import useSortOrder from 'hooks/useSortOrder';

import useLocalStorage from 'hooks/useLocalStorageSetting';
import { useAppFilters } from 'web/contexts/AppFiltersContext';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';

import SearchFilter from 'components/filters/SearchFilter';

import DesktopLayout, {
	ArchiveHeader,
	ArchiveHeaderRow,
	Content,
} from 'web/components/Layout';
import SortIcon from 'web/components/SortIcon';
import Table, { TableRow, TableCell } from 'web/components/Table';

import LoadMoreButton from 'web/components/LoadMoreButton';

import { PAGINATE_TENANTS } from 'api/queries/tenantQueries';

const RowWrapper = styled.div`
	position: relative;
	grid-column: 1 / -1;
`;

export default function AuditorCompaniesArchive() {
	const { setValue: setTentant } = useLocalStorage('tenant');
	const [{ orderBy, order }, setNewOrder] = useSortOrder('name');

	const { search } = useAppFilters();

	const {
		data: { companies = { count: 0, items: [] } } = {
			companies: { count: 0, items: [] },
		},
		loading,
		error,
		fetchMore,
	} = useQuery(PAGINATE_TENANTS, {
		variables: {
			search,
			orderBy,
			order,
			offset: 0,
			limit: 50,
		},
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		notifyOnNetworkStatusChange: true,
	});

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Bedrifter',
				},
			]}
			title="Bedrifter"
		>
			<Content>
				<ErrorMessage errors={error} />

				<ArchiveHeader>
					<ArchiveHeaderRow>
						<SearchFilter label="Søk etter bedrift" />
					</ArchiveHeaderRow>
				</ArchiveHeader>

				<Table columns="10">
					<TableRow header>
						<TableCell
							start="1"
							end="12"
							onClick={() => setNewOrder('name')}
						>
							<span>Navn</span>

							<SortIcon
								field="name"
								orderBy={orderBy}
								order={order}
							/>
						</TableCell>
					</TableRow>

					{!loading && companies?.items?.length === 0 && (
						<TableRow transparent>
							<TableCell start="1" end="-1" center>
								Ingen bedrifter ble funnet
							</TableCell>
						</TableRow>
					)}

					{!error &&
						companies?.items?.map(c => (
							<RowWrapper key={c._id} className="row">
								<TableRow hover>
									<TableCell
										to={`/bedrifter/${c._id}/`}
										onClick={() => setTentant(c)}
										start="1"
										end="12"
									>
										{c.name}
									</TableCell>
								</TableRow>
							</RowWrapper>
						))}

					{loading && (
						<TableRow transparent>
							<TableCell start="1" end="-1">
								<InlineLoading />
							</TableCell>
						</TableRow>
					)}

					{!error && !loading && companies?.items?.length !== 0 && (
						<TableRow footer>
							<TableCell start="1" end="-1" right>
								Viser {companies.items.length} av{' '}
								{companies.count} bedrifter
							</TableCell>
						</TableRow>
					)}
				</Table>

				{companies && companies.count > companies.items.length && (
					<LoadMoreButton
						disabled={loading}
						onClick={() => {
							fetchMore({
								variables: {
									offset: companies.items.length,
								},
								updateQuery: (prev, { fetchMoreResult }) => {
									if (
										fetchMoreResult.companies.items
											.length === 0
									) {
										return prev;
									}

									const result = {
										companies: {
											...prev.companies,
											items: [
												...prev.companies.items,
												...fetchMoreResult.companies
													.items,
											],
										},
									};

									return result;
								},
							});
						}}
					/>
				)}
			</Content>
		</DesktopLayout>
	);
}
