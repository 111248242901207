import Icon from 'components/Icon';

export default function SortIcon({ order }) {
	const getIcon = () => {
		if (order === 1) return 'sort-amount-up';
		if (order === -1) return 'sort-amount-down';
		return 'bars-sort';
	};

	return <Icon size="sm" icon={getIcon()} />;
}
