import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';

import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';
import Form, {
	FormGroup,
	TextField,
	Switch,
	AutoComplete,
} from 'web/form/Form';
import ErrorMessage from 'components/ErrorMessage';

import { validateEmail, validatePhone } from 'utils/strings';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import useLocalStorage from 'hooks/useLocalStorageSetting';

import { FIND_TENANT_USERS } from 'api/queries/userQueries';
import { UPDATE_SIGNATURE_REQUEST_SIGNEES } from 'api/mutations/signatureMutations';

export default function EditSigneeDialog({
	isOpen,
	setIsOpen,
	signee,
	signees,
	signatureRequestId,
}) {
	const { notify } = useSnackbar();

	const { value: tenant } = useLocalStorage('tenant');

	const { data: { users } = { users: [] } } = useQuery(FIND_TENANT_USERS, {
		variables: {
			tenantId: tenant?._id,
		},
	});

	const [updateSignees, { error }] = useMutation(
		UPDATE_SIGNATURE_REQUEST_SIGNEES,
		{
			refetchQueries: ['getActiveSignatureRequest'],
		}
	);

	const userOptions = users?.items?.map(user => {
		return {
			label: user.name,
			value: user._id,
			tooltip: [
				<Stack key="1" direction="row" alignItems="center" gap={1}>
					<MailIcon />
					{user.email || 'Mangler e-post'}
				</Stack>,
				<Stack key="2" direction="row" alignItems="center" gap={1}>
					<PhoneIcon />
					{user.phone || 'Mangler telefonnr'}
				</Stack>,
			],
		};
	});

	const [selectUser, setSelectUser] = useState(!!signee?.userId);

	async function handleSaveSignee(newSignee) {
		try {
			const editSigneeIndex = signees.findIndex(
				s => s.userId === signee.userId
			);

			const modifiedSignees = [...signees];
			modifiedSignees[editSigneeIndex] = newSignee; // Replace the old signee with the new signee

			const updatedSignees = [];
			modifiedSignees.map(s => {
				updatedSignees.push({
					phone: s.phone || '',
					email: s.email || '',
					userId: s.userId || '',
				});
			});

			// Update the signees
			const { data } = await updateSignees({
				variables: {
					_id: signatureRequestId,
					signees: updatedSignees,
				},
			});

			if (data) {
				notify('Signeringen ble oppdatert');
				setIsOpen(false);
			}
		} catch (err) {
			console.error(err);
		}
	}

	function handleClose() {
		setIsOpen(false);
	}

	function validateValues(values, setError) {
		if (!values.email && !values.phone) {
			setError('email', {
				type: 'manual',
				message: 'Fyll ut e-postadresse',
			});
			setError('phone', {
				type: 'manual',
				message: 'eller telefonnummer',
			});
			return false;
		}

		let isValid = true;

		if (values.email && !validateEmail(values.email)) {
			setError('email', {
				type: 'manual',
				message: 'Ugyldig e-postadresse',
			});
			isValid = false;
		}

		if (values.phone && !validatePhone(values.phone)) {
			setError('phone', {
				type: 'manual',
				message: 'Ugyldig telefonnummer',
			});
			isValid = false;
		}

		return isValid;
	}

	if (!signee) {
		return null;
	}

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			fullWidth={true}
			maxWidth="xs"
			aria-labelledby="dialog-title"
		>
			<DialogTitle id="dialog-title">Rediger signering</DialogTitle>

			<DialogCloseButton onClick={handleClose} />

			<ErrorMessage title="Kunne ikke lagre endringer" errors={[error]} />

			<Form
				values={{
					email: signee?.email,
					phone: signee?.phone,
					user: signee?.userId,
				}}
				onSubmit={async (values, methods) => {
					if (selectUser) {
						if (!values.user) {
							console.error('No user selected');
							return;
						}

						handleSaveSignee({
							userId: values.user,
							email: '',
							phone: '',
						});
					} else {
						const { setError } = methods;

						const isValid = validateValues(values, setError);
						if (!isValid) return;

						handleSaveSignee({
							userId: '',
							email: values.email,
							phone: values.phone,
						});
					}
				}}
			>
				<DialogContent>
					<FormGroup>
						<Switch
							label="Velg bruker"
							labelPlacement="start"
							name="typeSwitch"
							checked={selectUser}
							onChange={() => {
								setSelectUser(!selectUser);
							}}
						/>
					</FormGroup>

					{!selectUser && <ManualFields />}

					{selectUser && userOptions && (
						<UserFields userOptions={userOptions} />
					)}
				</DialogContent>

				<DialogActions>
					<Button
						type="submit"
						variant="contained"
						startIcon={<SaveIcon />}
					>
						Oppdater
					</Button>
				</DialogActions>
			</Form>
		</Dialog>
	);
}

function ManualFields() {
	const { clearErrors } = useFormContext();

	return (
		<>
			<FormGroup>
				<TextField
					type="email"
					fullWidth
					label="E-postadresse"
					name="email"
					onFocus={() => clearErrors()}
				/>
			</FormGroup>
			<FormGroup>
				<TextField
					type="tel"
					fullWidth
					label="Telefonnummer"
					name="phone"
					onFocus={() => clearErrors()}
				/>
			</FormGroup>
		</>
	);
}

function UserFields({ userOptions }) {
	return (
		<AutoComplete
			name="user"
			label="Bruker"
			options={userOptions || []}
			renderOption={(props, option) => (
				<li {...props} key={`${option.value}`}>
					<Option option={option}>{option.label}</Option>
				</li>
			)}
			required
		/>
	);
}

function Option({ children, option }) {
	if (!option.tooltip) {
		return <span>{children}</span>;
	}

	return (
		<Tooltip
			title={
				<div>
					{option.tooltip.map((t, i) => (
						<div key={i}>{t}</div>
					))}
				</div>
			}
		>
			<span>{children}</span>
		</Tooltip>
	);
}
