import { gql } from '@apollo/client';

export const FIND_ONE_COMPANY_CLASSIFICATIN_CODE = gql`
	query findOneCompanyClassificationCode($code: String) {
		classification: findOneCompanyClassificationCode(code: $code) {
			code
			name
		}
	}
`;
